@if (error) {
  <div class="error">
    <div class="title">{{ title }}</div>
    <div>{{ message }}</div>
    <div class="detaljer">
      @if (logId) {
        <small class="detaljer-link" (click)="detailsVisible = !detailsVisible">Detaljer</small>
      }
      @if (detailsVisible) {
        <pre>{{ details }}</pre>
      }
    </div>
  </div>
}
