import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    selector: "dff-side-tekst",
    template: `
        @if (titel) {
          <h2>{{ titel }}</h2>
        }
        @if (tekst) {
          <div [outerHTML]="tekst"></div>
        }
        `,
    styles: [
        `
            :host {
                display: block;
                margin-bottom: 20px;
            }
            :host ::ng-deep p {
                margin: 0 0 5px 0;
            }
            strong {
                display: block;
                margin: 0 0 5px 0;
            }
            h2 {
                font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
                letter-spacing: normal;
                margin: 0 0 5px 0;
                font-weight: 600;
            }
            p {
                margin: 0 0 5px 0;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DffSideTekstComponent {
    @Input() public titel: string;
    @Input() public tekst: string;
}
