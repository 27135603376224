import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { mdiLockOpenVariantOutline, mdiLockOutline } from "@mdi/js";

import { defineCustomElements, SvgIconRegistry } from "@dffedb/util";

import { DffDropdownButtonModule } from "../shared/dff-dropdown-button";
import { EForsyningLogindKnapComponent } from "./e-forsyning-logind-knap.component";

const menuIconEforsyning = `
    <g xmlns="http://www.w3.org/2000/svg" clip-path="url(#clip0_423_4183)">
        <path d="M38.9058 5.16666H0V0H38.9058V5.16666ZM19.1702 18.0833H0V12.9166H19.1702V18.0833ZM19.1702 31H0V25.8333H19.1702V31Z" fill="inherit" />
        <path d="M27.0788 31H22.3257L22.2804 22.3222H19.7412L29.1444 10.6077L38.5682 22.367L35.8918 22.3222L36.0743 31H31.2568L31.1264 26.1342L27.0143 26.1496L27.0788 31Z" fill="inherit"/>
    </g>
    <defs xmlns="http://www.w3.org/2000/svg">
        <clipPath id="clip0_423_4183">
            <rect width="40" height="31" fill="inherit" />
        </clipPath>
    </defs>`;

@NgModule({
    imports: [CommonModule, MatIconModule, DffDropdownButtonModule],
    exports: [],
    declarations: [EForsyningLogindKnapComponent],
    providers: []
})
export class EForsyningLogindKnapModule {
    constructor(injector: Injector, svgIconRegistry: SvgIconRegistry) {
        svgIconRegistry.registerIcon(mdiLockOutline, "lock");
        svgIconRegistry.registerIcon3(menuIconEforsyning, 40, 31, "lock-open");
        defineCustomElements("e-forsyning-logind-knap", EForsyningLogindKnapComponent, { injector });
    }
}
