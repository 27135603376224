@if (vm.visible) {
  <dff-dropdown-button titel="{{ titel }}" [svgIcon]="vm.svgIcon" class="driftsstatus-dropdown-btn">
    <div [class]="class">
      @if (vm.active) {
        <div class="overlay">
          <p class="text">{{ driftsstatusforstyrrelsertekst }}</p>
          <a [href]="vm.href">Læs mere <span class="sr-only">om driftsforstyrrelser</span></a>
        </div>
      }
      @if (!vm.active) {
        <div class="overlay">
          <span class="text">{{ driftsstatusingenforstyrrelsertekst }}</span>
        </div>
      }
    </div>
  </dff-dropdown-button>
}
