<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input
    matInput
    #dateinput
    [matDatepicker]="datepicker"
    [formControl]="formControl"
    [max]="max"
    [min]="min"
    [required]="required"
    [attr.aria-required]="required"
    [attr.aria-label]="label"
    (focus)="datepicker.open()"
    />
    <mat-datepicker-toggle matSuffix [for]="datepicker" tabIndex="-1"></mat-datepicker-toggle>
    <mat-datepicker #datepicker (closed)="dateinput.blur()"></mat-datepicker>
    @if (hint) {
      <mat-hint>{{ hint }}</mat-hint>
    }
    @if (formControl.errors) {
      <mat-error>{{ getErrorText(formControl) }}</mat-error>
    }
  </mat-form-field>
