import { DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { PrisberegnerFormModel } from "../model/prisberegner-form.model";
import {
    PrisberegnerKonfiguration,
    PrisberegnerNaturgasKonfiguration,
    PrisberegnerOlieKonfiguration,
    PrisberegnerTraepillerKonfiguration,
    PrisberegnerVarmepumpeKonfiguration
} from "../model/prisberegner-konfiguration.model";
import {
    PrisberegnerResultat,
    PrisberegnerResultatFjernvarme,
    PrisberegnerResultatNaturgas,
    PrisberegnerResultatOlie,
    PrisberegnerResultatVarmepumpe
} from "../model/prisberegner-resultat.model";
import { prisberegnerVarmekilder } from "../model/prisberegner-varmekilder.model";
import { PrisberegnerResultatDetaljelinjeViewModel, PrisberegnerResultaterViewModel } from "../model/prisberegner.view-model";

@Injectable({ providedIn: "root" })
export class PrisberegnerViewModelMapper {
    constructor(private readonly sanitizer: DomSanitizer) {}

    public modelToViewModel(
        konfiguration: PrisberegnerKonfiguration,
        model: PrisberegnerFormModel,
        resultat: PrisberegnerResultat
    ): PrisberegnerResultaterViewModel {
        const fjernvarmeDetaljer = Array.from(this.genererDetaljerFjernvarme(konfiguration, model, resultat.fjernvarme, resultat.mwh));
        const sammenligningskildeDetaljer = this.genererDetaljerSammenligningskilde(konfiguration, model, resultat);
        const sammenligningskildeIaltLinje = sammenligningskildeDetaljer.find((d) => d.bold);
        const fjernvarmeIaltLinje = fjernvarmeDetaljer.find((d) => d.bold);
        const fjernvarmeIalt = fjernvarmeIaltLinje?.beloeb || 0;
        const sammenligningkildeIalt = sammenligningskildeIaltLinje?.beloeb || 0;
        const besparelseKr = !!sammenligningkildeIalt ? sammenligningkildeIalt - fjernvarmeIalt : 0;
        const besparelsePct = !!sammenligningkildeIalt ? (besparelseKr * 100) / sammenligningkildeIalt : 0;
        return {
            mwh: resultat.mwh,
            mwhForklaring: this.genererMwhForklaring(konfiguration, model),
            fjernvarme: {
                detaljer: fjernvarmeDetaljer,
                ialt: fjernvarmeIaltLinje
            },
            sammenligningskilde: {
                detaljer: sammenligningskildeDetaljer,
                ialt: sammenligningskildeIaltLinje || this.createDetaljer("", "", 0, "", false)
            },
            besparelseKr,
            besparelsePct
        };
    }

    private *genererDetaljerFjernvarme(
        konfiguration: PrisberegnerKonfiguration,
        model: PrisberegnerFormModel,
        resultat: PrisberegnerResultatFjernvarme,
        mwh: number
    ): IterableIterator<PrisberegnerResultatDetaljelinjeViewModel> {
        const format = new DecimalPipe("da-DK");
        const boligareal = format.transform(model.boligareal, "1.0-0") + " m<sup>2</sup>";
        const bbrPrisPrM2 = this.formatKr(konfiguration.fjernvarme.bbrPrisPrM2);
        const antalMwh = format.transform(mwh, "1.2-2") + " MWh";
        const prisPrMwh = this.formatKr(konfiguration.fjernvarme.prisPrMwh);
        const investeringsomkostninger = this.formatKr(konfiguration.fjernvarme.investeringsomkostninger);
        const nyUnitPris = this.formatKr(konfiguration.fjernvarme.nyUnitPris);
        const nyUnitAfskrivningsperiode = konfiguration.fjernvarme.nyUnitAfskrivningsperiodeAar + " år";
        const nyUnitRentesats = konfiguration.fjernvarme.nyUnitRentesats + "%";
        const groenOmstillingsbidragPrM2 = this.formatKr(konfiguration.fjernvarme.groenOmstillingsbidragPrM2);
        const tekst = konfiguration.tekster;
        const suffixBeloeb = "kr.";
        const suffixBeloebPrAar = "kr./år";

        if (resultat.forbrug) {
            yield this.createDetaljer(tekst.energipris, `(${antalMwh} à ${prisPrMwh})`, resultat.forbrug, suffixBeloebPrAar, false);
        }

        if (resultat.abonnement) {
            yield this.createDetaljer(tekst.abonnementsbidrag, "", resultat.abonnement, suffixBeloebPrAar, false);
        }

        if (resultat.bbrBidrag) {
            yield this.createDetaljer(tekst.bbrBidrag, `(${boligareal} à ${bbrPrisPrM2})`, resultat.bbrBidrag, suffixBeloebPrAar, false);
        }

        if (resultat.bbrBidragTrappemodel.ialt) {
            yield this.createDetaljer(tekst.bbrBidrag, "", resultat.bbrBidragTrappemodel.ialt, suffixBeloebPrAar, false);
            for (const item of resultat.bbrBidragTrappemodel.items) {
                const arealFra = format.transform(item.fraM2, "1.0-0");
                const arealTil = format.transform(item.tilM2, "1.0-0");
                const areal = `${arealFra}-${arealTil} m<sup>2</sup>`;
                const prisPrM2 = this.formatKr(item.prisPrM2);
                yield this.createDetaljer(null, `- ${areal} à ${prisPrM2}`, null, null, false, "detail");
            }
        }

        if (resultat.groenOmstillingsbidrag) {
            yield this.createDetaljer(
                tekst.groenOmstillingsbidrag,
                `(${boligareal} à ${groenOmstillingsbidragPrM2})`,
                resultat.groenOmstillingsbidrag,
                suffixBeloebPrAar,
                false
            );
        }

        if (resultat.varmemesterOrdning) {
            yield this.createDetaljer(tekst.varmemesterordning, "", resultat.varmemesterOrdning, suffixBeloebPrAar, false);
        }

        if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer(tekst.serviceOgVedligehold, "", resultat.serviceOgVedligehold, suffixBeloebPrAar, false);
        }

        if (resultat.unitGenanskaffelseYdelse) {
            yield this.createDetaljer(
                tekst.koebAfFjernvarmeunit,
                `(${nyUnitPris} betalt over ${nyUnitAfskrivningsperiode}, rente ${nyUnitRentesats})`,
                resultat.unitGenanskaffelseYdelse,
                suffixBeloebPrAar,
                false
            );
        }

        if (resultat.investeringsomkostningerYdelse) {
            yield this.createDetaljer(
                tekst.investeringsomkostninger,
                `(${investeringsomkostninger} betalt over ${nyUnitAfskrivningsperiode}, rente ${nyUnitRentesats})`,
                resultat.investeringsomkostningerYdelse,
                suffixBeloebPrAar,
                false
            );
        }

        if (model.oenskerFordelingAfKonverteringsbidrag) {
            yield this.createDetaljer(
                tekst.konverteringsbidragOverskrift,
                `(${resultat.bbrBidragTrappemodel.konverteringsbidragIalt} kr. fordelt over ${model.konverteringbidragAntalAar} år)`,
                model.konverteringbidragAntalAar > 0
                    ? resultat.bbrBidragTrappemodel.konverteringsbidragIalt / model.konverteringbidragAntalAar
                    : resultat.bbrBidragTrappemodel.konverteringsbidragIalt,
                suffixBeloebPrAar,
                false
            );
            for (const item of resultat.bbrBidragTrappemodel.items) {
                const arealFra = format.transform(item.fraM2, "1.0-0");
                const arealTil = format.transform(item.tilM2, "1.0-0");
                const areal = `${arealFra}-${arealTil} m<sup>2</sup>`;
                const prisPrM2 = this.formatKr(item.konverteringsbidragPrM2);
                yield this.createDetaljer(null, `- ${areal} à ${prisPrM2}`, null, null, false, "detail");
            }
        }

        if (resultat.varmemesterOrdning) {
            yield this.createDetaljer("I alt", "inkl. moms, service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        } else if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer("I alt", "inkl. moms, service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        } else {
            yield this.createDetaljer("I alt", "inkl. moms, ekskl. service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        }

        if (resultat.tilslutningsbidrag) {
            yield this.createDetaljer(tekst.tilslutningsbidrag, "", resultat.tilslutningsbidrag, suffixBeloeb, false);
        }

        //konverteringsbidrag uden fordeling
        if (!model.oenskerFordelingAfKonverteringsbidrag) {
            if (resultat.bbrBidragTrappemodel.konverteringsbidragIalt > 0) {
                yield this.createDetaljer(
                    tekst.konverteringsbidragOverskrift,
                    "",
                    resultat.bbrBidragTrappemodel.konverteringsbidragIalt,
                    suffixBeloeb,
                    false
                );
            }
            for (const item of resultat.bbrBidragTrappemodel.items) {
                const arealFra = format.transform(item.fraM2, "1.0-0");
                const arealTil = format.transform(item.tilM2, "1.0-0");
                const areal = `${arealFra}-${arealTil} m<sup>2</sup>`;
                const prisPrM2 = this.formatKr(item.konverteringsbidragPrM2);
                yield this.createDetaljer(null, `- ${areal} à ${prisPrM2}`, null, null, false, "detail");
            }
        }
    }

    private genererDetaljerSammenligningskilde(
        konfiguration: PrisberegnerKonfiguration,
        model: PrisberegnerFormModel,
        resultat: PrisberegnerResultat
    ): PrisberegnerResultatDetaljelinjeViewModel[] {
        if (model.varmekilde === prisberegnerVarmekilder.naturgas.id) {
            return Array.from(this.genererDetaljerNaturgas(konfiguration.naturgas, model, resultat.naturgas));
        }
        if (model.varmekilde === prisberegnerVarmekilder.olie.id) {
            return Array.from(this.genererDetaljerOlie(konfiguration.olie, model, resultat.olie));
        }
        if (model.varmekilde === prisberegnerVarmekilder.traepiller.id) {
            return Array.from(this.genererDetaljerTraepiller(konfiguration.traepiller, model, resultat.traepiller));
        }
        if (model.varmekilde === prisberegnerVarmekilder.varmepumpe.id) {
            return Array.from(this.genererDetaljerVarmepumpe(konfiguration.varmepumpe, model, resultat.varmepumpe, resultat.mwh));
        }
        return [];
    }

    private genererMwhForklaring(konfiguration: PrisberegnerKonfiguration, model: PrisberegnerFormModel): string {
        const format = new DecimalPipe("da-DK");
        if (model.varmekilde === prisberegnerVarmekilder.varmepumpe.id) {
            return `beregnet med en COP-værdi på ${format.transform(konfiguration.varmepumpe.virkningsgrad * 100, "1.0-0")}%`;
        }
        return "";
    }

    private *genererDetaljerNaturgas(
        konfiguration: PrisberegnerNaturgasKonfiguration,
        model: PrisberegnerFormModel,
        resultat: PrisberegnerResultatNaturgas
    ): IterableIterator<PrisberegnerResultatDetaljelinjeViewModel> {
        const format = new DecimalPipe("da-DK");
        const antalM3 = format.transform(model.forbrugNaturgas, "1.0-0") + " m<sup>3</sup>";
        const prisPrM3 = this.formatKr(konfiguration.prisPrM3);
        const nytFyrPris = this.formatKr(konfiguration.nytFyrPris);
        const nytFyrAfskrivningsperiode = konfiguration.nytFyrAfskrivningsperiodeAar + " år";
        const nytFyrRentesats = konfiguration.nytFyrRentesats + "%";
        const suffixBeloebPrAar = "kr./år";

        if (resultat.forbrug) {
            yield this.createDetaljer("Forbrug", `(${antalM3} à ${prisPrM3})`, resultat.forbrug, suffixBeloebPrAar, false);
        }

        if (resultat.unitGenanskaffelseYdelse) {
            yield this.createDetaljer(
                "Køb af gasfyr",
                `(${nytFyrPris} betalt over ${nytFyrAfskrivningsperiode}, rente ${nytFyrRentesats})`,
                resultat.unitGenanskaffelseYdelse,
                suffixBeloebPrAar,
                false
            );
        }

        if (resultat.abonnement) {
            yield this.createDetaljer("Abonnement", "", resultat.abonnement, suffixBeloebPrAar, false);
        }

        if (resultat.systemtarif) {
            yield this.createDetaljer("Systemtarif (G1.6, G2.4, G4)", "", resultat.systemtarif, suffixBeloebPrAar, false);
        }

        if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer("Service og vedligehold", "", resultat.serviceOgVedligehold, suffixBeloebPrAar, false);
        }

        if (resultat.elForbrug) {
            yield this.createDetaljer("Udgifter til el", "", resultat.elForbrug, suffixBeloebPrAar, false);
        }

        if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer("I alt", "inkl. moms, service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        } else {
            yield this.createDetaljer("I alt", "inkl. moms, ekskl. service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        }
    }

    private *genererDetaljerOlie(
        konfiguration: PrisberegnerOlieKonfiguration,
        model: PrisberegnerFormModel,
        resultat: PrisberegnerResultatOlie
    ): IterableIterator<PrisberegnerResultatDetaljelinjeViewModel> {
        const format = new DecimalPipe("da-DK");
        const antalLiter = format.transform(model.forbrugOlie, "1.0-0") + " liter";
        const prisPrLiter = this.formatKr(konfiguration.prisPrLiter);
        const nytFyrPris = this.formatKr(konfiguration.nytFyrPris);
        const nytFyrAfskrivningsperiode = konfiguration.nytFyrAfskrivningsperiodeAar + " år";

        const nytFyrRentesats = konfiguration.nytFyrRentesats + "%";
        const suffixBeloebPrAar = "kr./år";

        if (resultat.forbrug) {
            yield this.createDetaljer("Forbrug", `(${antalLiter} à ${prisPrLiter})`, resultat.forbrug, suffixBeloebPrAar, false);
        }

        if (resultat.unitGenanskaffelseYdelse) {
            yield this.createDetaljer(
                "Køb af oliefyr",
                `(${nytFyrPris} betalt over ${nytFyrAfskrivningsperiode}, rente ${nytFyrRentesats})`,
                resultat.unitGenanskaffelseYdelse,
                suffixBeloebPrAar,
                false
            );
        }

        if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer("Service og vedligehold", "", resultat.serviceOgVedligehold, suffixBeloebPrAar, false);
        }

        if (resultat.skorstensfejer) {
            yield this.createDetaljer("Skorstensfejer", "", resultat.skorstensfejer, suffixBeloebPrAar, false);
        }

        if (resultat.elForbrug) {
            yield this.createDetaljer("Udgifter til el", "", resultat.elForbrug, suffixBeloebPrAar, false);
        }

        if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer("I alt", "inkl. moms, service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        } else {
            yield this.createDetaljer("I alt", "inkl. moms, ekskl. service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        }
    }

    private *genererDetaljerTraepiller(
        konfiguration: PrisberegnerTraepillerKonfiguration,
        model: PrisberegnerFormModel,
        resultat: PrisberegnerResultatOlie
    ): IterableIterator<PrisberegnerResultatDetaljelinjeViewModel> {
        const format = new DecimalPipe("da-DK");
        const antalKg = format.transform(model.forbrugTraepiller, "1.0-0") + " kg";
        const prisPrKg = this.formatKr(konfiguration.prisPrKg);
        const nytFyrPris = this.formatKr(konfiguration.nytFyrPris);
        const nytFyrAfskrivningsperiode = konfiguration.nytFyrAfskrivningsperiodeAar + " år";
        const nytFyrRentesats = konfiguration.nytFyrRentesats + "%";
        const suffixBeloebPrAar = "kr./år";

        if (resultat.forbrug) {
            yield this.createDetaljer("Forbrug", `(${antalKg} à ${prisPrKg})`, resultat.forbrug, suffixBeloebPrAar, false);
        }

        if (resultat.unitGenanskaffelseYdelse) {
            yield this.createDetaljer(
                "Køb af pillefyr",
                `(${nytFyrPris} betalt over ${nytFyrAfskrivningsperiode}, rente ${nytFyrRentesats})`,
                resultat.unitGenanskaffelseYdelse,
                suffixBeloebPrAar,
                false
            );
        }

        if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer("Service og vedligehold", "", resultat.serviceOgVedligehold, suffixBeloebPrAar, false);
        }

        if (resultat.skorstensfejer) {
            yield this.createDetaljer("Skorstensfejer", "", resultat.skorstensfejer, suffixBeloebPrAar, false);
        }

        if (resultat.elForbrug) {
            yield this.createDetaljer("Udgifter til el", "", resultat.elForbrug, suffixBeloebPrAar, false);
        }

        if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer("I alt", "inkl. moms, service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        } else {
            yield this.createDetaljer("I alt", "inkl. moms, ekskl. service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
        }
    }

    private *genererDetaljerVarmepumpe(
        konfiguration: PrisberegnerVarmepumpeKonfiguration,
        model: PrisberegnerFormModel,
        resultat: PrisberegnerResultatVarmepumpe,
        mwh: number
    ): IterableIterator<PrisberegnerResultatDetaljelinjeViewModel> {
        const format = new DecimalPipe("da-DK");
        const forbrugEl = `${format.transform(model.forbrugVarmepumpe, "1.0-0")} kWh`;
        const prisPrKwh = this.formatKr(konfiguration.elprisPrKwh);
        const nyPumpePris = this.formatKr(konfiguration.nyPumpePris);
        const nyPumpeAfskrivningsperiode = konfiguration.nyPumpeAfskrivningsperiodeAar + " år";
        const nyPumpeRentesats = konfiguration.nyPumpeRentesats + "%";
        const suffixBeloebPrAar = "kr./år";

        if (resultat.elUdgift) {
            yield this.createDetaljer("Eludgift", `(${forbrugEl} à ${prisPrKwh})`, resultat.elUdgift, suffixBeloebPrAar, false);
        }

        if (resultat.serviceOgVedligehold) {
            yield this.createDetaljer("Service og vedligehold", "", resultat.serviceOgVedligehold, suffixBeloebPrAar, false);
        }

        if (resultat.unitGenanskaffelseYdelse) {
            yield this.createDetaljer(
                "Køb af varmepumpe",
                `(${nyPumpePris} betalt over ${nyPumpeAfskrivningsperiode}, rente ${nyPumpeRentesats})`,
                resultat.unitGenanskaffelseYdelse,
                suffixBeloebPrAar,
                false
            );
        }

        yield this.createDetaljer("I alt", "inkl. moms, service og vedligehold", resultat.ialt, suffixBeloebPrAar, true);
    }

    private createDetaljer(
        tekst: string,
        tekst2: string,
        beloeb: number,
        beloebSuffix: string,
        bold: boolean,
        cssClasses: string = ""
    ): PrisberegnerResultatDetaljelinjeViewModel {
        return {
            tekst,
            tekst2: this.sanitizer.bypassSecurityTrustHtml(tekst2),
            beloeb,
            beloebSuffix,
            bold,
            cssClasses: bold ? "bold " : "" + cssClasses
        };
    }

    private formatKr(value: number): string {
        const format = new DecimalPipe("da-DK");
        const result = value % 1 ? format.transform(value, "1.2-2") : format.transform(value, "1.0-0");
        return `${result} kr.`;
    }
}
