import { Injectable } from "@angular/core";

import { dbToBoolean, dbToFloat, dbToString } from "@dffedb/util";

import { PrisberegnerKonfigurationBbrPriserDto, PrisberegnerKonfigurationDto } from "../model/prisberegner-konfiguration.dto";
import {
    PrisberegnerFjernvarmeBbrPrisKonfiguration,
    PrisberegnerKonfiguration,
    PrisberegnerVarmekilde
} from "../model/prisberegner-konfiguration.model";
import { prisberegnerVarmekilder } from "../model/prisberegner-varmekilder.model";

@Injectable({ providedIn: "root" })
export class PrisberegnerKonfigurationMapper {
    public dtoToKonfiguration(dto: PrisberegnerKonfigurationDto): PrisberegnerKonfiguration {
        const bbrTrappemodel = dbToBoolean(dto.fjernvarmeBbrTrappemodel);
        const result: PrisberegnerKonfiguration = {
            fjernvarme: {
                prisAbonnement: dbToFloat(dto.fjernvarmePrisAbonnement),
                bbrTrappemodel,
                bbrPrisPrM2: bbrTrappemodel ? null : dbToFloat(dto.fjernvarmeBbrPrisPrM2),
                bbrPriserPrM2: bbrTrappemodel ? this.dtoBbrPriserToKonfiguration(JSON.parse(dto.fjernvarmeBbrPriserPrM2 ?? "[]")) : [],
                prisPrMwh: dbToFloat(dto.fjernvarmePrisPrMwh),
                groenOmstillingsbidragPrM2: dbToFloat(dto.fjernvarmePrisGroenOmstillingsbidrag),
                fjernvarmeVarmemesterordningStandardVaerdi: dbToBoolean(dto.fjernvarmeVarmemesterordningStandardVaerdi),
                tilslutningsbidrag: dbToFloat(dto.fjernvarmeTilslutningsbidragFastPris),
                varmemesterordningPrAar: dbToFloat(dto.fjernvarmeVarmemesterordningPrisPrAar),
                serviceOgVedligeholdPrAar: dbToFloat(dto.fjernvarmeServiceOgVedligeholdPrAar),
                investeringsomkostninger: dbToFloat(dto.fjernvarmeInvesteringsomkostninger),
                nyUnitPris: dbToFloat(dto.fjernvarmeNyUnitPris),
                nyUnitAfskrivningsperiodeAar: dbToFloat(dto.fjernvarmeNyUnitAfskrivningsperiodeAar),
                nyUnitRentesats: this.parseRentesat(dto.fjernvarmeNyUnitRentesats),
                konverteringsbidragAntalAar: this.parseAntalAar(dto.fjernvarmeKonverteringbidragAntalAar)
            },
            naturgas: {
                braendvaerdiKwhPrM3: dbToFloat(dto.naturgasBraendvaerdiKwhPrM3),
                virkningsgrad: {
                    vaelgKedlensAlder: dbToBoolean(dto.naturgasVirkningsgradVaelgKedlensAlder),
                    virkningsgrad: this.parseVirkningsgrad(dto.naturgasVirkningsgrad),
                    virkningsgrad0til4Aar: this.parseVirkningsgrad(dto.naturgasVirkningsgrad0til4Aar),
                    virkningsgrad5til8Aar: this.parseVirkningsgrad(dto.naturgasVirkningsgrad5til8Aar),
                    virkningsgrad9tilNAar: this.parseVirkningsgrad(dto.naturgasVirkningsgrad9tilNAar)
                },
                abonnementPrAar: dbToFloat(dto.natrurgasPrisAbonnementPrAar),
                systemtarif: dbToFloat(dto.naturgasPrisSystemtarif),
                elPrAar: dbToFloat(dto.naturgasPrisElPrAar),
                prisPrM3: dbToFloat(dto.naturgasPrisPrM3),
                serviceOgVedligeholdPrAar: dbToFloat(dto.naturgasPrisServiceOgVedligeholdPrAar),
                nytFyrPris: dbToFloat(dto.naturgasNytFyrPris),
                nytFyrAfskrivningsperiodeAar: dbToFloat(dto.naturgasNytFyrAfskrivningsperiodeAar),
                nytFyrRentesats: this.parseRentesat(dto.naturgasNytFyrRentesats)
            },
            olie: {
                braendvaerdiKwhPrLiter: dbToFloat(dto.olieBraendvaerdiKwhPrLiter),
                virkningsgrad: {
                    vaelgKedlensAlder: dbToBoolean(dto.olieVirkningsgradVaelgFyretsAlder),
                    virkningsgrad: this.parseVirkningsgrad(dto.olieVirkningsgrad),
                    virkningsgrad0til4Aar: this.parseVirkningsgrad(dto.olieVirkningsgrad0Til4Aar),
                    virkningsgrad5til8Aar: this.parseVirkningsgrad(dto.olieVirkningsgrad5Til8Aar),
                    virkningsgrad9tilNAar: this.parseVirkningsgrad(dto.olieVirkningsgrad9TilNAar)
                },
                elPrAar: dbToFloat(dto.olieElforbrugPrAar),
                prisPrLiter: dbToFloat(dto.oliePrisPrLiter),
                serviceOgVedligeholdPrAar: dbToFloat(dto.olieServiceOgVedligeholdPrAar),
                skorstensfejerPrAar: dbToFloat(dto.olieSkorstensfejerPrAar),
                nytFyrPris: dbToFloat(dto.olieNytFyrPris),
                nytFyrAfskrivningsperiodeAar: dbToFloat(dto.olieNytFyrAfskrivningsperiodeAar),
                nytFyrRentesats: this.parseRentesat(dto.olieNytFyrRentesats)
            },
            traepiller: {
                braendvaerdiKwhPrKg: dbToFloat(dto.traepillerBraendvaerdiKwhPrKg),
                virkningsgrad: {
                    vaelgKedlensAlder: dbToBoolean(dto.traepillerVgVaelgFyretsAlder),
                    virkningsgrad: this.parseVirkningsgrad(dto.traepillerVirkningsgrad),
                    virkningsgrad0til4Aar: this.parseVirkningsgrad(dto.traepillerVirkningsgrad0Til4Aar),
                    virkningsgrad5til8Aar: this.parseVirkningsgrad(dto.traepillerVirkningsgrad5Til8Aar),
                    virkningsgrad9tilNAar: this.parseVirkningsgrad(dto.traepillerVirkningsgrad9TilNAar)
                },
                elPrAar: dbToFloat(dto.traepillerElforbrugPrAar),
                prisPrKg: dbToFloat(dto.traepillerPrisPrKg),
                serviceOgVedligeholdPrAar: dbToFloat(dto.traepillerServiceOgVedligeholdPrAar),
                skorstensfejerPrAar: dbToFloat(dto.traepillerSkorstensfejerPrAar),
                nytFyrPris: dbToFloat(dto.traepillerNytFyrPris),
                nytFyrAfskrivningsperiodeAar: dbToFloat(dto.traepillerNytFyrAfskrivningsperiodeAar),
                nytFyrRentesats: this.parseRentesat(dto.traepillerNytFyrRentesats)
            },
            varmepumpe: {
                virkningsgrad: this.parseVirkningsgrad(dto.varmepumpeVirkningsgrad),
                elprisPrKwh: dbToFloat(dto.varmepumpeElprisPrKwh),
                serviceOgVedligeholdPrAar: dbToFloat(dto.varmepumpeServiceOgVedligeholdPrAar),
                nyPumpePris: dbToFloat(dto.varmepumpeNyPumpePris),
                nyPumpeAfskrivningsperiodeAar: dbToFloat(dto.varmepumpeNyPumpeAfskrivningsperiodeAar),
                nyPumpeRentesats: this.parseRentesat(dto.varmepumpeNyPumpeRentesats)
            },
            tekster: {
                abonnementsbidrag: dto.tekstAbonnementsbidrag !== "" ? dto.tekstAbonnementsbidrag : "Abonnementsbidrag",
                bbrBidrag: dto.tekstBbrBidrag !== "" ? dto.tekstBbrBidrag : "BBR bidrag",
                energipris: dto.tekstEnergipris !== "" ? dto.tekstEnergipris : "Energipris",
                investeringsomkostninger:
                    dto.tekstInvesteringsomkostninger !== "" ? dto.tekstInvesteringsomkostninger : "Investeringsomkostninger",
                koebAfFjernvarmeunit: dto.tekstKoebAfFjernvarmeunit !== "" ? dto.tekstKoebAfFjernvarmeunit : "Køb af fjernvarmeunit",
                medVarmemesterordning: dto.tekstMedVarmemesterordning !== "" ? dto.tekstMedVarmemesterordning : "Ønsker varmemesterordning",
                serviceOgVedligehold: dto.tekstServiceOgVedligehold !== "" ? dto.tekstServiceOgVedligehold : "Service og vedligehold",
                groenOmstillingsbidrag: dto.tekstGroenOmstillingsbidrag !== "" ? dto.tekstGroenOmstillingsbidrag : "Grøn omstillingsbidrag",
                tilslutningsbidrag: dto.tekstTilslutningsbidrag !== "" ? dto.tekstTilslutningsbidrag : "Tilslutningsbidrag",
                varmemesterordning: dto.tekstVarmemesterordning !== "" ? dto.tekstVarmemesterordning : "Varmemesterordning",
                konverteringsbidragOverskrift:
                    dto.tekstKonverteringsbidragOverskrift !== "" ? dto.tekstKonverteringsbidragOverskrift : "Konverteringsbidrag",
                konverteringsbidragSubtekst1:
                    dto.tekstKonverteringsbidragSubtekst1 !== ""
                        ? dto.tekstKonverteringsbidragSubtekst1
                        : "Konverteringsbidraget kan betales med det samme, eller fordeles over et antal år.",
                konverteringsbidragSubtekst2:
                    dto.tekstKonverteringsbidragSubtekst2 !== "" ? dto.tekstKonverteringsbidragSubtekst2 : "Klik og vælg din løsning",
                konverteringsbidragToggle1:
                    dto.tekstKonverteringsbidragToggle1 !== "" ? dto.tekstKonverteringsbidragToggle1 : "Engangsbetaling af",
                konverteringsbidragToggle2:
                    dto.tekstKonverteringsbidragToggle2 !== "" ? dto.tekstKonverteringsbidragToggle2 : "Fordel betalingen med"
            },
            varmekilder: this.dtoToVarmekilder(dto.varmekilder),
            visDetaljeretResultat: dto.visDetaljeretResultat === "1",
            visKunUdgiftResultat: dto.visKunUdgiftResultat === "1"
        };

        return result;
    }

    private dtoBbrPriserToKonfiguration(dto: PrisberegnerKonfigurationBbrPriserDto[]): PrisberegnerFjernvarmeBbrPrisKonfiguration[] {
        // Værdier er gemt som "12.5", så vi benytter parseFloat (decimalseparator .) i stedet for dbToFloat (decimalseparator ,)
        return (dto ?? []).map((d) => ({
            tekst: dbToString(d.tekst),
            fraM2: parseFloat(d.fraM2),
            tilM2: parseFloat(d.tilM2),
            prisPrM2: parseFloat(d.prisPrM2),
            konverteringsbidragPrM2: parseFloat(d.konverteringsbidragPrM2)
        }));
    }

    private dtoToVarmekilder(varmekilder: string): PrisberegnerVarmekilde[] {
        const ids = (varmekilder || "").split(",").map((id) => +id);
        return ids.map((id) => prisberegnerVarmekilder.alle().find((vk) => vk.id === id));
    }

    // "4%" => 4
    // "4" => 4 => 4 (implicit %-tegn)
    // "0,04" => 4
    // "0,19" => 19 (19%)-
    // "0,20" => 0.20
    private parseRentesat(value: string): number {
        const result = dbToFloat(value);
        return result > 0.2 ? result : result * 100; // Vi har nok ikke rentesats på 20% eller derover
    }

    // "325" => 325 => 3.25 (implicit %-tegn)
    // "325%" => 3.25
    // "3,25" => 3.25
    // "95" => 95 => 0.95 (implicit %-tegn)
    // "95%" => 0.95
    // "0,95" => 0.95
    private parseVirkningsgrad(value: string): number {
        const result = dbToFloat(value);
        return result < 10 ? result : result / 100; // Vi har nok hverken COP-værdi eller virkningsgrad på 1000% eller derover
    }

    private parseAntalAar(value: string): number[] {
        return (value || "").split(",").map(Number);
    }
}
