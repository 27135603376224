@if (settings$ | async; as settings) {
    @if (indstillinger$ | async; as indstillinger) {
        <!-- '#top-navbar' pilles runtime ud af ASP.NET/C# siden og indsættes her -->
        <!-- Desktop -->
        @if (settings.isDesktop) {
            <div class="desktop-container">
                <ng-container *ngTemplateOutlet="leftmenu"></ng-container>
                <div>
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </div>
            </div>
        }
        <!-- Mobil -->
        @if (settings.isMobile) {
            <!-- .navbar -->
            <div class="navbar-mobile" #navbarMobile>
                <div
                    class="navbar-mobile__top backgroundcolor2"
                    id="mobile-menu-top-content"
                    [class]="scrollPositionTop || sidenavRight.opened || sidenavLeft.opened ? '' : 'on-scroll'"
                >
                    @if (indstillinger.eforsyninger?.length) {
                        <button
                            #LeftMobileMenuButton
                            id="LeftMobileMenuButton"
                            class="menu-button left"
                            (click)="sidenavLeft.toggle(); sidenavRight.close()"
                            [attr.aria-label]="sidenavLeft.opened ? 'Luk sidemenu' : 'Åben sidemenu'"
                            [attr.aria-expanded]="sidenavLeft.opened ? true : false"
                            aria-controls="sidenavLeft"
                            type="button"
                            role="button"
                        >
                            <mat-icon [svgIcon]="eforsyningIcon$ | async"></mat-icon>
                            @if ((eforsyningIcon$ | async) === "lock-open") {
                                <span>E|Forsyning</span>
                            }
                        </button>
                    }
                    <a [href]="indstillinger.forsyningWeb?.siteUrl">
                        @if (indstillinger.logoUrl) {
                            <img [src]="indstillinger.logoUrl" [alt]="indstillinger.firmaNavn" />
                        }
                        @if (!indstillinger.logoUrl) {
                            <span>{{ indstillinger.firmaNavn }}</span>
                        }
                    </a>
                    @if (indstillinger?.forsyningWeb.nodeId) {
                        <button
                            #RightMobileMenuButton
                            id="RightMobileMenuButton"
                            class="menu-button right"
                            [attr.aria-label]="sidenavRight.opened ? 'Luk hovedmenu' : 'Åben hovedmenu'"
                            [attr.aria-expanded]="sidenavRight.opened ? true : false"
                            aria-controls="sidenavRight"
                            type="button"
                            role="button"
                            (click)="sidenavRight.toggle(); sidenavLeft.close()"
                        >
                            <mat-icon svgIcon="menu-forsyning-web"></mat-icon>
                            <span>Forsyning|Web</span>
                        </button>
                    }
                </div>
                <div
                    class="navbar-mobile__bottom backgroundcolor2"
                    id="mobile-menu-bottom-content"
                    [class]="scrollPositionTop || sidenavRight.opened || sidenavLeft.opened ? '' : 'on-scroll'"
                >
                    <ng-container *ngTemplateOutlet="vaelgInstallation"></ng-container>
                    <ng-container *ngTemplateOutlet="breadcrumb"></ng-container>
                    @if (settings.visDriftsstatus) {
                        <driftsstatus-knap class="backgroundcolor2" titel="Driftsstatus" [visIkon]="false"></driftsstatus-knap>
                    }
                </div>
            </div>
            <!-- content -->
            <div
                class="sidenav-wrapper"
                [style.top.px]="scrollPositionTop || sidenavRight.opened || sidenavLeft.opened ? (navbarMobileHeight$ | async) : (navbarMobileHeight$ | async) - 65"
            >
                <mat-sidenav-container #sidenavContainer>
                    <mat-sidenav #sidenavLeft>
                        <eforsyning-sidenav-menu
                            id="sidenavLeft"
                            aria-labelledby="LeftMobileMenuButton"
                            role="navigation"
                            type="navigation"
                            aria-label="Side menu"
                            [attr.aria-hidden]="!sidenavLeft.opened"
                            [attr.aria-expanded]="sidenavLeft.opened"
                            (itemClick)="sidenavContainer.close()"
                            [visLoggetUdMenu]="true"
                        ></eforsyning-sidenav-menu>
                    </mat-sidenav>
                    <mat-sidenav
                        #sidenavRight
                        position="end"
                        id="sidenavRight"
                        aria-labelledby="RightMobileMenuButton"
                        role="navigation"
                        type="navigation"
                        aria-label="Hoved menu"
                        [attr.aria-hidden]="!sidenavRight.opened"
                        [attr.aria-expanded]="sidenavRight.opened"
                    >
                        <forsyningweb-sidenav-menu (itemClick)="sidenavContainer.close()"></forsyningweb-sidenav-menu>
                    </mat-sidenav>
                    <mat-sidenav-content id="MatSidenavContent" #sidenavContent>
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </mat-sidenav-content>
                </mat-sidenav-container>
            </div>
        }
    }
}

<!-- Indhold, indsættes i enten Desktop eller Mobil-containeren -->
<ng-template #content><ng-content></ng-content></ng-template>
<ng-template #breadcrumb><ng-content select=".breadcrumb"></ng-content></ng-template>
<ng-template #vaelgInstallation><ng-content select=".vaelg-installation"></ng-content></ng-template>
<ng-template #leftmenu><ng-content select=".leftmenu"></ng-content></ng-template>
