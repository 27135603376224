import { Component, EventEmitter, Input, Output } from "@angular/core";

import { DffRedirectService } from "@dffedb/router";

import { MenuItem } from "../model/menu-item.model";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
    selector: "dff-sidenav-menu",
    templateUrl: "./dff-sidenav-menu.component.html",
    styleUrls: ["./dff-sidenav-menu.component.scss"],
    animations: [
        trigger("inOutAnimation", [
            transition(":enter", [style({ transform: "translateX(100%)" }), animate("0.4s ease-out", style({ transform: "translateX(0)" }))]),
            transition(":leave", [style({ transform: "translateX(0)" }), animate("0.4s ease-in", style({ transform: "translateX(100%)" }))])
        ])
    ]
})
export class DffSidenavMenuComponent {
    @Input()
    public items: MenuItem[];
    @Input()
    public hasTabIndex = true;

    @Output()
    public itemClick: EventEmitter<MouseEvent> = new EventEmitter();

    constructor(private readonly redirectService: DffRedirectService) { } // TODO NIM: Findes ikke i Forsyning|WEB

    public currentItem = null;
    public isSubmenuVisible = false;

    get isMobile(): boolean {
        return window.innerWidth < 768;
    }

    public openSubmenu(item: MenuItem) {
        this.currentItem = item;
        this.isSubmenuVisible = true;
        if (!item.children && item.children[0] !== null) return;

        setTimeout(() => {
            const submenu = document.getElementById(item.id + "-sub-menu");
            if (!submenu) return;
            const firstFocusableElement = submenu.querySelector('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])') as HTMLElement;

            if (!firstFocusableElement) return;
            firstFocusableElement.focus();
        }, 500);
    }

    public closeSubmenu() {
        this.currentItem = null;
        this.isSubmenuVisible = false;
    }

    public getMenuItemId(itemId: number | string, itemType: string): string {
        if (!itemId) return "";
        return itemId + "-" + itemType;
    }

    public killClick(event: MouseEvent): void {
        event.stopPropagation();
    }

    public onItemClick(event: MouseEvent, href: string): void {
        // Hvis der ikke er en url at navigere til (menupunktet Installation for forbruger med én installation)
        // sørger vi for at ignorere klikket. Vi ønsker hverken at navigere eller collapse side-menuen.
        if (!href) {
            event.preventDefault();
            return;
        }
        // Fix: iOS burde ikke fange intern routing og kan dermed ikke vælge at åbne siden i Safari.
        // Fixet bør fjernes når vi engang har tilrettet vores iOS App til at genkende url'er fra Umbraco.
        const route = (href || "").split("#")[1];
        if (route && !!this.redirectService) {
            event.preventDefault();
            this.redirectService.redirect(route);
        }
        // Send event videre, så vi kan få collapset side-menuen
        this.itemClick.emit(event);
    }
}
