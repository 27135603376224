<mat-form-field>
  <mat-label>{{ placeholder }}</mat-label>
  <input matInput type="email" [formControl]="formControl" />
  @if (hint) {
    <mat-hint>{{ hint }}</mat-hint>
  }
  @if (formControl.errors) {
    <mat-error>{{ getErrorText(formControl) }}</mat-error>
  }
</mat-form-field>
