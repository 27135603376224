@if (items) {
    <ng-content></ng-content>
}
@for (item of items; track item) {
    <li class="menu-item" [className]="item.children ? 'menu-item-internal' : 'menu-item-leaf'" role="listitem">
        @if (!item.children?.length) {
            @if (item.route) {
                <a
                    [routerLink]="item.route"
                    (click)="onItemClick($event, item.href)"
                    [attr.title]="item.title"
                    routerLinkActive="active"
                    [id]="getMenuItemId(item.id ?? item.title ?? '', 'menu-item')"
                    [attr.tabindex]="hasTabIndex ? 0 : -1"
                >
                    @if (item.icon) {
                        <mat-icon [svgIcon]="item.icon"></mat-icon>
                    }
                    <span>{{ item.title }}</span></a
                >
            }
            @if (!item.route) {
                <a
                    [href]="item.href"
                    (click)="onItemClick($event, item.href)"
                    [attr.title]="item.title"
                    [id]="getMenuItemId(item.id ?? item.title ?? '', 'menu-item')"
                    [attr.tabindex]="hasTabIndex ? 0 : -1"
                >
                    @if (item.icon) {
                        <mat-icon [svgIcon]="item.icon"></mat-icon>
                    }
                    <span>{{ item.title }}</span></a
                >
            }
        }
        @if (item.children && item.children.length && !isMobile) {
            <mat-expansion-panel class="mat-elevation-z0" (opened)="hasTabIndex = true" (closed)="hasTabIndex = false">
                <mat-expansion-panel-header>
                    @if (item.icon) {
                        <mat-icon [svgIcon]="item.icon"></mat-icon>
                    }
                    <span>
                        {{ item.title }}
                    </span>
                </mat-expansion-panel-header>
                <dff-sidenav-menu
                    [items]="item.children"
                    [hasTabIndex]="hasTabIndex"
                    (itemClick)="itemClick.emit($event)"
                    [id]="getMenuItemId(item.id ?? item.title ?? '', 'sidenav-menu')"
                    role="list"
                ></dff-sidenav-menu>
            </mat-expansion-panel>
        }
        @if (item.children && item.children.length && isMobile) {
            <div class="menu-item__with-sub-menu">
                <button
                    (click)="openSubmenu(item)"
                    (keydown.enter)="openSubmenu(item)"
                    (keydown.space)="openSubmenu(item)"
                    [attr.aria-expanded]="!isSubmenuVisible"
                    [attr.aria-controls]="getMenuItemId(item.id ?? item.title ?? '', 'sub-menu')"
                    role="button"
                    type="button"
                    class="btn-with-sub-menu"
                    [id]="getMenuItemId(item.id ?? item.title ?? '', 'menu-item')"
                    [attr.title]="item.title"
                    [attr.tabindex]="hasTabIndex ? 0 : -1"
                >
                    <span class="btn-with-sub-menu__wrapper">
                        @if (item.icon) {
                            <mat-icon [svgIcon]="item.icon"></mat-icon>
                        }
                        <span>{{ item.title }}</span>
                    </span>
                    <mat-icon svgIcon="arrow-right"></mat-icon>
                </button>
                @if (isSubmenuVisible) {
                    <div
                        class="child-menu"
                        [@inOutAnimation]
                        role="menu"
                        [id]="getMenuItemId(item.id ?? item.title ?? '', 'sub-menu')"
                        [attr.aria-hidden]="!isSubmenuVisible"
                    >
                        <button
                            class="child-menu__go-back"
                            (click)="closeSubmenu()"
                            (keydown.enter)="closeSubmenu()"
                            (keydown.space)="closeSubmenu()"
                            aria-label="Gå tilbage til tidligere menu"
                            [attr.aria-controls]="getMenuItemId(item.id ?? item.title ?? '', 'sub-menu')"
                            role="button"
                            type="button"
                            [attr.tabindex]="hasTabIndex ? 0 : -1"
                        >
                            <mat-icon svgIcon="arrow-left"></mat-icon>
                            <span>Gå tilbage</span>
                        </button>
                        <dff-sidenav-menu
                            [items]="currentItem.children"
                            (itemClick)="itemClick.emit($event)"
                            [id]="getMenuItemId(item.id ?? item.title ?? '', 'sidenav-menu')"
                            role="list"
                        ></dff-sidenav-menu>
                    </div>
                }
            </div>
        }
    </li>
}
