<div [class]="data.cssClass">
  <div class="top backgroundcolor1">
    <mat-icon [svgIcon]="data.icon"></mat-icon>
  </div>
  @if (data.titel) {
    <strong>{{ data.titel }}</strong>
  }
  @if (data.tekst) {
    <p>{{ data.tekst }}</p>
  }
</div>
<mat-dialog-actions align="center">
  <button mat-flat-button mat-dialog-close color="primary">OK</button>
</mat-dialog-actions>