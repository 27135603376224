import { Component, ContentChild, HostBinding, Input } from "@angular/core";
import { MatSlider } from "@angular/material/slider";

@Component({
    selector: "dff-mat-slider-wrapper",
    templateUrl: "./dff-mat-slider-wrapper.component.html",
    styleUrls: ["./dff-mat-slider-wrapper.component.scss"]
})
export class DffMatSliderWrapperComponent {
    @HostBinding("class") public class = "dff-mat-slider-wrapper";
    @ContentChild(MatSlider) public matSlider: MatSlider;

    @Input() public suffix: string;

    constructor() {}

    // TODO 14420: Should maybe just be removed, as transform label is now part of angular slider
    // public getSliderValueLabelTransform(): string {
    //     const percent = (this.matSlider && this.matSlider.step) || 0;
    //     return `translateX(${percent * 100 - 50}%)`;
    // }

    public formatValue(value: number): string | number {
        return (this.matSlider && this.matSlider.displayWith(value)) || "";
    }
}
