import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from "@angular/core";

import { Observable } from "rxjs";

import { MenuItem } from "@dffedb/ui";

import { ForsyningWebMenuForSidebarObserver } from "../observers/forsyning-web-menu-for-sidebar.observer";

@Component({
    selector: "forsyningweb-sidenav-menu",
    templateUrl: "./forsyningweb-sidenav-menu.component.html",
    styleUrls: ["./forsyningweb-sidenav-menu.component.scss"]
})
export class ForsyningwebSidenavMenuComponent implements OnInit {
    public menuItems$: Observable<MenuItem[]>;

    @Output()
    public itemClick = new EventEmitter<MouseEvent>();

    constructor(private readonly menuObserver: ForsyningWebMenuForSidebarObserver, private menu: ElementRef) {}

    @HostListener("keydown.tab", ["$event"])
    onTab(event: KeyboardEvent) {
        const focusableElements = this.menu.nativeElement.querySelectorAll('button, [href], [tabindex]:not([tabindex="-1"])');
        const firstElement = focusableElements[0];
        const lastElement = focusableElements[focusableElements.length - 1];
        if (document.activeElement === lastElement && !event.shiftKey) {
            console.log(focusableElements, lastElement);
            event.preventDefault();
            this.itemClick.emit();
        }
        if (document.activeElement === firstElement && event.shiftKey) {
            event.preventDefault();
            this.itemClick.emit();
        }
    }

    public ngOnInit(): void {
        this.menuItems$ = this.menuObserver.valueChanges;
    }
}
