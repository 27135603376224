@if (error) {
  <div>
    <h4>Der er opstået en fejl</h4>
    <p>{{ message }}</p>
    @if (logId) {
      <small (click)="detailsVisible = !detailsVisible">{{ logId }}</small>
    }
    @if (detailsVisible) {
      <pre>{{ details }}</pre>
    }
  </div>
}
