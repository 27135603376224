import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { combineLatest, Observable, of } from "rxjs";
import { delay, map, shareReplay, startWith, switchMap } from "rxjs/operators";

import { UmbracoHttpClient } from "@e-forsyning/common/http";
import { UmbracoIndstillingerObserver } from "@e-forsyning/common/umbraco-indstillinger";

import { AdresseSearchDto, CheckOmDerLeveresPaaAdressenResultDto, ForsyningPaaAdresseKonfigurationDto } from "../model/forsyning-paa-adresse.dto";
import { AdresseSearch, ForsyningPaaAdresseKonfiguration, ForsyningPaaAdresseLeveringStatus, ForsyningPaaAdresseResult } from "../model/forsyning-paa-adresse.model";

@Injectable({ providedIn: "root" })
export class ForsyningPaaAdresseRepository {
    constructor(
        private readonly indstillinger: UmbracoIndstillingerObserver,
        private readonly httpClient: HttpClient,
        private readonly umbracoHttpClient: UmbracoHttpClient
    ) {}

    public selectKonfiguration(nodeId: number): Observable<ForsyningPaaAdresseKonfiguration> {
        return this.umbracoHttpClient.hentSideDataForId<ForsyningPaaAdresseKonfigurationDto>(nodeId).pipe(
            map((dto) => ({
                eforsyningNodeId: +dto.eforsyningNodeId,
                svarmuligheder: {
                    ja: dto.svarmulighedJa,
                    nej: dto.svarmulighedNej,
                    snart: dto.svarmulighedPlanlagt
                },
                afgraensning: {
                    postNr: (dto.afgraensningPostNr?.split("\n") ?? []).filter((p) => !!p)
                },
                cssLeveresFjernvarme: dto.cssLeveresFjernvarme
            }))
        );
    }

    public findAdresser(value: string, postNr: string[]): Observable<AdresseSearch[]> {
        const result = postNr?.length
            ? combineLatest(postNr.map((pnr) => this.httpClient.get<AdresseSearchDto[]>(`https://dawa.aws.dk/adresser/autocomplete?q=${value}&postnr=${pnr}`))).pipe(
                  map((r) => ([] as AdresseSearchDto[]).concat(...r))
              )
            : this.httpClient.get<AdresseSearchDto[]>(`https://dawa.aws.dk/adresser/autocomplete?q=${value}`);

        return result.pipe(
            map((r) => r.map((v) => this.mapAdresse(v))),
            shareReplay(1)
        );
    }

    public checkOmDerLeveresPaaAdressen(eforsyningNodeId: number, adresse: AdresseSearch): Observable<ForsyningPaaAdresseResult> {
        //console.log("Tjekker om der leveres på adressen", eforsyningNodeId, adresse);
        return this.indstillinger.valueChanges.pipe(
            map((i) => i.eforsyninger.find((e) => e.nodeId === eforsyningNodeId)),
            switchMap((e) => this.httpCheckOmDerLeveresPaaAdressen(e.appServerUrl, adresse)),
            delay(1000),
            startWith({ ejendomNr: 0, status: ForsyningPaaAdresseLeveringStatus.Arbejder, statusTekst: null, tekst: null }),
            shareReplay(1)
        );
    }

    private httpCheckOmDerLeveresPaaAdressen(appServerUrl: string, adresse: AdresseSearch): Observable<ForsyningPaaAdresseResult> {
        if (!adresse?.adresse) {
            return of(null);
        }
        const etage = adresse.adresse.etage || ""; // API kan ikke håndtere 'null'
        const doer = adresse.adresse.doer || ""; // API kan ikke håndtere 'null'
        const url = `${appServerUrl}api/LeveresFjernvarmePaaAdresse?kommunenr=${adresse.adresse.kommunenr}&vejnr=${adresse.adresse.vejnr}&husnr=${adresse.adresse.husnr}&husnrBogstav=${adresse.adresse.husnrBogstav}&etage=${etage}&doer=${doer}`;
        return this.httpClient.get<CheckOmDerLeveresPaaAdressenResultDto>(url).pipe(map((result) => this.mapForsyningPaaAdresseResult(result, adresse.tekst)));
    }

    private mapForsyningPaaAdresseResult(dto: CheckOmDerLeveresPaaAdressenResultDto, adresseTekst: string): ForsyningPaaAdresseResult {
        return {
            ejendomNr: +(dto.ejendomNr ?? 1),
            status: +dto.status as ForsyningPaaAdresseLeveringStatus,
            statusTekst: +dto.status === ForsyningPaaAdresseLeveringStatus.LevererIFremtiden ? dto.tekst : null,
            tekst: adresseTekst
        };
    }

    private mapAdresse(dto: AdresseSearchDto): AdresseSearch {
        const husnrMatch = dto.adresse.husnr.match(/([0-9]+)(.*)/);
        const husnr = husnrMatch ? +husnrMatch[1] : null;
        const husnrBogstav = husnrMatch ? husnrMatch[2] : null;
        return {
            tekst: dto.tekst,
            adresse: {
                kommunenr: +dto.adresse.kommunekode,
                vejnr: +dto.adresse.vejkode,
                husnr,
                husnrBogstav,
                etage: dto.adresse.etage,
                doer: dto.adresse.dør,
                postnr: dto.adresse.postnr
            }
        };
    }
}
