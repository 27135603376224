@if (vm$ | async; as vm) {
  <form [formGroup]="formGroup" action="">
    <mat-form-field>
      <mat-label>Søg på adresse</mat-label>
      <input matInput type="search" formControlName="searchValue" autocorrect="off" [matAutocomplete]="autocomplete" />
    </mat-form-field>
    <mat-autocomplete
      #autocomplete="matAutocomplete"
      [autoActiveFirstOption]="true"
      (optionSelected)="onSearchValueChange($event.option.value)"
      [displayWith]="displayFn"
      >
      @for (adresse of adresser$ | async; track adresse) {
        <mat-option [value]="adresse">
          {{ adresse.tekst }}
        </mat-option>
      }
    </mat-autocomplete>
    @if (result$ | async; as result) {
      <div class="result">
        @switch (result.status) {
          @case (-1) {
            <div><small>Henter data...</small></div>
          }
          @case (0) {
            <div [innerHTML]="result.statusTekst || vm.konfiguration.svarmuligheder.nej"></div>
          }
          @case (1) {
            <div [innerHTML]="result.statusTekst || vm.konfiguration.svarmuligheder.ja"></div>
          }
          @case (2) {
            <div [innerHTML]="result.statusTekst || vm.konfiguration.svarmuligheder.snart"></div>
          }
          @default {
            <div></div>
          }
        }
      </div>
    }
  </form>
}
