<form #form="ngForm" [formGroup]="formGroup" class="step-oplysninger">
  @if (false) {
    <pre>
      {{ formGroup.getRawValue() | json }}
    </pre>
  }
  <div class="privat-eller-virksomhed">
    <mat-label color="accent" (click)="privatEllerVirksomhedToggle.toggle()"
    [class.active]="!privatEllerVirksomhedToggle.checked">Privat</mat-label>
    <mat-slide-toggle class="always-colored" color="primary" formControlName="erVirksomhed"
    #privatEllerVirksomhedToggle></mat-slide-toggle>
    <mat-label (click)="privatEllerVirksomhedToggle.toggle()" [class.active]="privatEllerVirksomhedToggle.checked">
    Erhverv</mat-label>
  </div>

  <div class="step-content">
    @if (!formGroup.control('erVirksomhed').value) {
      <mat-form-field class="large-input">
        <mat-label>For- og efternavn</mat-label>
        <input formControlName="navn" type="text" matInput required autocomplete="name" />
        @if (formGroup.get('navn').errors?.required) {
          <mat-error> Indtast dit fulde navn </mat-error>
        }
        @if (formGroup.get('navn').errors?.maxlength) {
          <mat-error> Navn må indeholde 30 tegn </mat-error>
        }
      </mat-form-field>
    }
    @if (formGroup.control('erVirksomhed').value) {
      <mat-form-field class="large-input">
        <mat-label>Firmanavn</mat-label>
        <input formControlName="navn" type="text" matInput required />
        @if (formGroup.get('navn').errors?.required) {
          <mat-error> Indtast virksomhedens navn </mat-error>
        }
        @if (formGroup.get('navn').errors?.maxlength) {
          <mat-error> Firmanavn må indeholde 30 tegn </mat-error>
        }
      </mat-form-field>
    }
    @if (formGroup.control('erVirksomhed').value) {
      <mat-form-field class="large-input">
        <mat-label>Kontaktperson</mat-label>
        <input formControlName="ekstraPersonNavn" type="text" matInput />
        @if (formGroup.get('ekstraPersonNavn').errors?.required) {
          <mat-error> Indtast kontaktpersonens fulde navn
          </mat-error>
        }
        @if (formGroup.get('ekstraPersonNavn').errors?.maxlength) {
          <mat-error> Kontaktperson må indeholde 30 tegn
          </mat-error>
        }
      </mat-form-field>
    }

    <mat-label class="adresse">{{adresse2}}</mat-label>

    <mat-form-field class="large-input">
      <mat-label>Telefon</mat-label>
      <input formControlName="telefon" type="tel" matInput required />
      @if (formGroup.get('telefon').hasError('required')) {
        <mat-error> Indtast telefonnummer </mat-error>
      }
      @if (formGroup.get('telefon').hasError('pattern')) {
        <mat-error> Indtast gyldigt telefonnummer </mat-error>
      }
    </mat-form-field>
    <mat-form-field class="large-input">
      <mat-label>E-mail</mat-label>
      <input formControlName="email" type="email" matInput required />
      @if (formGroup.get('email').hasError('required')) {
        <mat-error> Indtast e-mail </mat-error>
      }
      @if (formGroup.get('email').hasError('email')) {
        <mat-error> Indtast gyldig e-mail </mat-error>
      }
    </mat-form-field>
    <mat-form-field class="large-input">
      <mat-label>Gentag e-mail</mat-label>
      <input name="search" formControlName="emailGentag" type="email" matInput required
        autocomplete="dont-autofill-hack" />
        @if (formGroup.get('emailGentag').hasError('required')) {
          <mat-error> Indtast e-mail </mat-error>
        }
        @if (formGroup.get('emailGentag').hasError('email')) {
          <mat-error> Indtast gyldig e-mail </mat-error>
        }
        @if (formGroup.get('emailGentag').hasError('mustMatch') && !formGroup.get('emailGentag').hasError('email')) {
          <mat-error
            >
            De to e-mails stemmer ikke overens
          </mat-error>
        }
      </mat-form-field>
      @if (konfiguration.steps.personligeOplysninger.visBemaerkning) {
        <mat-form-field class="large-input">
          <mat-label>Bemærkning</mat-label>
          <textarea formControlName="bemaerkning" matInput matTextareaAutosize matAutosizeMinRows="2"> </textarea>
        </mat-form-field>
      }

      @if (konfiguration.visEBoksTilmelding) {
        <hr />
        @if (!formGroup.control('erVirksomhed').value) {
          <mat-checkbox formControlName="oenskerEboks" class="accepter-vilkaar">Jeg ønsker at modtage post via
          e-Boks</mat-checkbox>
          @if (formGroup.control('oenskerEboks').value) {
            <div class="step-content">
              <mat-form-field class="large-input">
                <mat-label>CPR-nummer</mat-label>
                <input formControlName="cprNummer" type="text" mask="000000-0000" matInput
                  [required]="isRequired('cprNummer')" />
                  @if (formGroup.get('cprNummer').invalid) {
                    <mat-error> Indtast dit CPR-nummer </mat-error>
                  }
                </mat-form-field>
                <mat-form-field class="large-input">
                  <mat-label>Gentag CPR-nummer</mat-label>
                  <input formControlName="cprNummerGentag" type="text" mask="000000-0000" matInput
                    [required]="isRequired('cprNummerGentag')" autocomplete="dont-autofill-hack" />
                    @if (formGroup.get('cprNummerGentag').hasError('required')) {
                      <mat-error> Indtast dit CPR-nummer
                      </mat-error>
                    }
                    @if (formGroup.get('cprNummerGentag').hasError('mustMatch') && !formGroup.get('cprNummerGentag').hasError('required')) {
                      <mat-error
                        >
                      De to CPR-numre stemmer ikke overens </mat-error>
                    }
                  </mat-form-field>
                  <div class="checkbox-wrapper">
                    <mat-checkbox formControlName="samtykkeTekstAccepteret" class="accepter-vilkaar"
                    id="mat-checkbox-samtykke">Jeg accepterer betingelserne</mat-checkbox>&nbsp;<a href="#"
                    (click)="onLaesSamtykkeClick($event, formGroup.control('samtykkeTekst').value)">(læs
                  betingelserne)</a>
                </div>
                @if (isErrorState('samtykkeTekstAccepteret')) {
                  <mat-error>
                    Vi har brug for samtykke for at kunne sende via e-Boks
                  </mat-error>
                }
              </div>
            }
          }
          @if (formGroup.control('erVirksomhed').value) {
            <mat-checkbox formControlName="oenskerEboks" class="accepter-vilkaar">Vi ønsker at modtage post via
            e-Boks</mat-checkbox>
            @if (formGroup.control('oenskerEboks').value) {
              <div class="step-content">
                <mat-form-field class="large-input">
                  <mat-label>CVR-nummer</mat-label>
                  <input formControlName="cvrNummer" type="text" mask="00000000" matInput
                    [required]="isRequired('cvrNummer')" />
                    @if (formGroup.get('cvrNummer').invalid) {
                      <mat-error> Indtast virksomhedens CVR-nummer
                      </mat-error>
                    }
                  </mat-form-field>
                  <mat-form-field class="large-input">
                    <mat-label>P-nummer</mat-label>
                    <input formControlName="pNummer" type="text" mask="0000000000" matInput />
                    @if (formGroup.get('pNummer').invalid) {
                      <mat-error> Indtast eventuelt virksomhedens P-nummer
                      </mat-error>
                    }
                  </mat-form-field>
                  <div class="checkbox-wrapper">
                    <mat-checkbox formControlName="samtykkeTekstAccepteret" class="accepter-vilkaar">Vi accepterer
                    betingelserne</mat-checkbox>&nbsp;<a href="#"
                    (click)="onLaesSamtykkeClick($event, formGroup.control('samtykkeTekst').value)">(læs
                  betingelserne)</a>
                </div>
                @if (isErrorState('samtykkeTekstAccepteret')) {
                  <mat-error>
                    Vi har brug for samtykke for at kunne sende via e-Boks
                  </mat-error>
                }
              </div>
            }
          }
        }
      </div>

      @if (!formGroup.control('erVirksomhed').value && konfiguration.visEBoksTilmelding) {
        <hr />
        <mat-slide-toggle formControlName="ekstraPerson">Er der flere ejere på adressen?</mat-slide-toggle>
        @if (formGroup.control('ekstraPerson').value) {
          <div class="step-content">
            <mat-form-field class="large-input">
              <mat-label>Medejers for og -efternavn</mat-label>
              <input formControlName="ekstraPersonNavn" type="text" matInput
                [required]="isRequired('ekstraPersonNavn')" />
                @if (formGroup.get('ekstraPersonNavn').errors?.required) {
                  <mat-error> Indtast medejers fulde navn
                  </mat-error>
                }
                @if (formGroup.get('ekstraPersonNavn').errors?.maxlength) {
                  <mat-error> Navn må indeholde 30 tegn
                  </mat-error>
                }
              </mat-form-field>
              <mat-form-field class="large-input">
                <mat-label>Telefon</mat-label>
                <input formControlName="ekstraPersonTelefon" type="tel" matInput />
                @if (formGroup.get('ekstraPersonTelefon').hasError('pattern')) {
                  <mat-error> Indtast gyldigt
                  telefonnummer </mat-error>
                }
              </mat-form-field>
              <mat-form-field class="large-input">
                <mat-label>E-mail</mat-label>
                <input formControlName="ekstraPersonEmail" type="email" matInput />
                @if (formGroup.get('ekstraPersonEmail').hasError('email')) {
                  <mat-error> Indtast gyldig e-mail
                  </mat-error>
                }
              </mat-form-field>
              <mat-form-field class="large-input">
                <mat-label>Gentag e-mail</mat-label>
                <input name="search" formControlName="ekstraPersonEmailGentag" type="email" matInput
                  [required]="isDirty('ekstraPersonEmail')" autocomplete="dont-autofill-hack" />
                  @if (formGroup.get('ekstraPersonEmailGentag').hasError('email')) {
                    <mat-error> Indtast gyldig e-mail
                    </mat-error>
                  }
                  @if (formGroup.get('ekstraPersonEmailGentag').hasError('mustMatch') && !formGroup.get('ekstraPersonEmailGentag').hasError('email')) {
                    <mat-error
                      >
                      De to e-mails stemmer ikke overens
                    </mat-error>
                  }
                </mat-form-field>
                @if (konfiguration.visEBoksTilmelding) {
                  <mat-checkbox formControlName="ekstraPersonOenskerEboks" class="accepter-vilkaar">Jeg (medejer) ønsker
                  at modtage post via e-Boks</mat-checkbox>
                  @if (formGroup.control('ekstraPersonOenskerEboks').value) {
                    <div class="step-content">
                      <mat-form-field class="large-input">
                        <mat-label>Medejers CPR-nummer</mat-label>
                        <input formControlName="ekstraPersonCprNummer" type="text" mask="000000-0000" matInput
                          [required]="isRequired('ekstraPersonCprNummer')" />
                          @if (formGroup.get('ekstraPersonCprNummer').invalid) {
                            <mat-error> Indtast medejers CPR-nummer
                            </mat-error>
                          }
                        </mat-form-field>
                        <mat-form-field class="large-input">
                          <mat-label>Gentag CPR-nummer</mat-label>
                          <input formControlName="ekstraPersonCprNummerGentag" type="text" mask="000000-0000" matInput
                            [required]="isRequired('ekstraPersonCprNummerGentag')" autocomplete="dont-autofill-hack" />
                            @if (formGroup.get('ekstraPersonCprNummerGentag').hasError('required')) {
                              <mat-error> Indtast
                              dit CPR-nummer </mat-error>
                            }
                            @if (formGroup.get('ekstraPersonCprNummerGentag').hasError('mustMatch') && !formGroup.get('ekstraPersonCprNummerGentag').hasError('required')) {
                              <mat-error
                                >
                              De to CPR-numre stemmer ikke overens </mat-error>
                            }
                          </mat-form-field>
                          <div class="checkbox-wrapper">
                            <mat-checkbox formControlName="ekstraPersonSamtykkeTekstAccepteret" class="accepter-vilkaar">Jeg
                            accepterer betingelserne</mat-checkbox>&nbsp;<a href="#"
                            (click)="onLaesSamtykkeClick($event, formGroup.control('ekstraPersonSamtykkeTekst').value)">(læs
                          betingelserne)</a>
                        </div>
                        @if (isErrorState('ekstraPersonSamtykkeTekstAccepteret')) {
                          <mat-error>
                            Vi har brug for samtykke for at kunne sende via e-Boks
                          </mat-error>
                        }
                      </div>
                    }
                  }
                </div>
              }
            }
          </form>