@if (vm.visible) {
  <dff-dropdown-button [titel]="vm.titel" [svgIcon]="vm.svgIcon" [href]="vm.href">
    <ul>
      @for (item of vm.items; track item) {
        <li>
          <a [href]="item.href" (click)="onClick($event, item.href)">
            @if (item.svgIcon) {
              <mat-icon [svgIcon]="item.svgIcon" [svgAlt]="'Lås ikon'"></mat-icon>
            }
            {{ item.titel }}
          </a>
        </li>
      }
    </ul>
  </dff-dropdown-button>
}
