<div>
  <mat-accordion displayMode="flat">
    @for (tilmelding of tilmeldinger; track tilmelding; let i = $index) {
      <mat-expansion-panel class="mat-elevation-z0" #panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            @if (tilmelding.cprNummer) {
              <div><small>CPR-nummer</small>{{ formatterCprNummer(tilmelding) }}</div>
            }
            @if (tilmelding.cvrNummer) {
              <div><small>CVR-nummer</small>{{ formatterCvrNummer(tilmelding) }}</div>
            }
            @if (tilmelding.pNummer) {
              <div><small>P-nummer</small>{{ formatterPNummer(tilmelding) }}</div>
            }
          </mat-panel-title>
          <div class="buttons">
            <button
              class="afmeld-button"
              mat-button
              (click)="onAfmeld($event, tilmelding)"
              color="warn"
              [disabled]="disabled || afmeldStatus.processing"
              id="button-eksisterende-tilmeldinger-{{ i }}"
              >
              @if (afmeldStatus.processing && afmeldStatus.value?.id === tilmelding.id) {
                <mat-spinner
                  class="mat-spinner"
                  color="basic"
                  diameter="25"
                ></mat-spinner>
              }
              SLET
            </button>
          </div>
        </mat-expansion-panel-header>
        <div class="vilkaar" [innerHTML]="tilmelding.samtykkeTekst"></div>
      </mat-expansion-panel>
    }
  </mat-accordion>
  @if (afmeldStatus.error) {
    <div class="error">
      {{ afmeldStatus.error }}
    </div>
  }
</div>
