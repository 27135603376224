import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { Observable, of, Subject } from "rxjs";
import { debounceTime, shareReplay, switchMap } from "rxjs/operators";

import { UnsubscribeOnDestroy } from "@dffedb/util";

import { AdresseSearch, ForsyningPaaAdresseResult } from "./model/forsyning-paa-adresse.model";
import { ForsyningPaaAdresseViewModel } from "./model/forsyning-paa-adresse.view-model";
import { ForsyningPaaAdresseSandbox } from "./services/forsyning-paa-adresse.sandbox";

@Component({
    selector: "forsyning-paa-adresse",
    templateUrl: "./forsyning-paa-adresse.component.html",
    styleUrls: ["./forsyning-paa-adresse.component.scss"],
    providers: [ForsyningPaaAdresseSandbox] // Sandbox indeholder state og der oprettes derfor en instans pr. Component
})
export class ForsyningPaaAdresseComponent extends UnsubscribeOnDestroy implements OnInit {
    @Input() public nodeId: number;
    @Output() public lookup = new EventEmitter<ForsyningPaaAdresseResult>();

    public adresser$: Observable<AdresseSearch[]> = of([]);
    private aktuelAdresseSubject: Subject<AdresseSearch> = new Subject<AdresseSearch>();
    public result$: Observable<ForsyningPaaAdresseResult>;
    public vm$: Observable<ForsyningPaaAdresseViewModel>;

    public formGroup = new UntypedFormGroup({
        searchValue: new UntypedFormControl("", [])
    });

    constructor(private readonly sandbox: ForsyningPaaAdresseSandbox) {
        super();
        this.adresser$ = this.formGroup.get("searchValue").valueChanges.pipe(
            debounceTime(200),
            switchMap((value) => (value.length >= 2 ? this.sandbox.findAdresser(value) : of([])))
        );
        this.result$ = this.aktuelAdresseSubject.pipe(
            switchMap((adresse) => this.sandbox.checkOmDerLeveresPaaAdressen(adresse)),
            shareReplay(1)
        );
        this.subs.add(this.result$.subscribe((result) => this.lookup.emit(result)));
    }

    public ngOnInit(): void {
        this.vm$ = this.sandbox.selectViewModel(+this.nodeId);
        this.addCustomStylesheet();
    }

    public displayFn(value: AdresseSearch): string {
        return value.tekst;
    }

    public onSearchValueChange(value: AdresseSearch): void {
        this.aktuelAdresseSubject.next(value);
    }

    public addCustomStylesheet(): void {
        if (!this.vm$) return;
        this.vm$.subscribe((vm) => {
            if (vm.konfiguration.cssLeveresFjernvarme && vm.konfiguration.cssLeveresFjernvarme !== "") {
                const style = document.createElement("style");
                style.innerHTML = vm.konfiguration.cssLeveresFjernvarme;
                document?.head.appendChild(style);
            }
        });
    }
}
