@if (error) {
  <empty-page-badge svgIcon="page-error-icon" overskrift="Der er opstået en fejl">
    <p>{{ message }}</p>
    @if (logId) {
      <small (click)="detailsVisible = !detailsVisible">{{ logId }}</small>
    }
    @if (detailsVisible) {
      <pre>{{ details }}</pre>
    }
  </empty-page-badge>
}
