<mat-tab-group
  [formGroup]="formGroup"
  #cprCvrTabGroup
  (selectedTabChange)="onSelectedTabChange($event.index)"
  [selectedIndex]="formGroup.get('type').value"
  color="primary"
  class="button-toggle"
  >
  <mat-tab label="Privatperson" formGroupName="person" [disabled]="tabDisabled">
    <mat-form-field class="large-input">
      <mat-label>CPR-nummer</mat-label>
      <input
        formControlName="cprNummer"
        type="text"
        mask="000000-0000"
        matInput
        required
        autofocus
        data-testid="cpr-nummer"
        id="input-cprNr"
        aria-label="CPR-nummer"
        [revealOnFocus]="revealOnFocus"
        />
      </mat-form-field>
      <div>
        <mat-form-field class="large-input">
          <mat-label>Gentag CPR-nummer</mat-label>
          <input
            formControlName="cprNummerGentag"
            type="text"
            mask="000000-0000"
            matInput
            required
            data-testid="cpr-nummer-gentag"
            id="input-cprNr-gentag"
            aria-label="Gentag CPR-nummer"
            [revealOnFocus]="revealOnFocus"
            />
          </mat-form-field>
          @if (skalGentagCprNummerFejlVises()) {
            <mat-error>Du har indtastet to forskellige CPR-numre</mat-error>
          }
        </div>
      </mat-tab>
      <mat-tab label="Virksomhed" formGroupName="virksomhed" [disabled]="tabDisabled">
        <div>
          <mat-form-field class="large-input">
            <mat-label>CVR-nummer</mat-label>
            <input
              formControlName="cvrNummer"
              type="text"
              mask="00000000"
              matInput
              required
              data-testid="cvr-nummer"
              id="input-cvrNr"
              aria-label="CVR-nummer"
              [revealOnFocus]="revealOnFocus"
              />
              <span matPrefix>DK&nbsp;</span>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field class="large-input">
              <mat-label>P-nummer</mat-label>
              <input
                formControlName="pNummer"
                type="text"
                mask="0000000000"
                matInput
                data-testid="p-nummer"
                id="input-pNr"
                aria-label="P-nummer"
                [revealOnFocus]="revealOnFocus"
                />
              </mat-form-field>
            </div>
          </mat-tab>
        </mat-tab-group>
