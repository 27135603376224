@if (iconClass) {
  <div class="custom-icon"><i class="{{ iconClass }}"></i></div>
}
@if (matIcon) {
  <mat-icon [inline]="true">{{ matIcon }}</mat-icon>
}
@if (svgIcon) {
  <mat-icon [inline]="true" [svgIcon]="svgIcon"></mat-icon>
}
<h3>{{ overskrift }}</h3>
<ng-content></ng-content>
