@if (vm$ | async; as vm) {
  @if (false) {
    <pre>
      {{ vm.formGroup.getRawValue() | json }}
    </pre>
  }
  <mat-vertical-stepper
    [linear]="true"
    #stepper
    color="primary"
    [class.stepper-visible]="stepperVisible"
    [class.stepper-invisible]="!stepperVisible"
    >
    <mat-step>
      <ng-template matStepLabel>Find adresse</ng-template>
      <div>
        <div class="step-content">
          <dff-side-tekst
            [titel]="vm.konfiguration.steps.adresseSoegning.overskrift"
            [tekst]="vm.konfiguration.steps.adresseSoegning.tekst"
          ></dff-side-tekst>
          <forsyning-paa-adresse [nodeId]="nodeId" (lookup)="onAdresseChange($event)"></forsyning-paa-adresse>
        </div>
        @if (vm.forsyningPaaAdresseResult.status === ForsyningPaaAdresseLeveringStatus.LevererIFremtiden) {
          <div>
            <mat-checkbox #minAdresse> Ja, det er min adresse </mat-checkbox>
            <div class="step-actions">
              <button mat-flat-button color="primary" [disabled]="!minAdresse.checked" (click)="stepper.next()">
                {{ vm.konfiguration.steps.adresseSoegning.knapTekst }}
              </button>
            </div>
          </div>
        }
      </div>
    </mat-step>
    @if (!!vm.ejendomsfaktorDefinitioner.length) {
      <mat-step>
        <ng-template matStepLabel>Oplysninger om ejendommen</ng-template>
        <div>
          <div class="step-content">
            <dff-side-tekst
              [titel]="vm.konfiguration.steps.ejendomsfaktorer.overskrift"
              [tekst]="vm.konfiguration.steps.ejendomsfaktorer.tekst"
            ></dff-side-tekst>
            <tilslutningsanmodning-step-ejendomsfaktorer
              [formArray]="vm.formGroup.array('ejendomsfaktorer')"
              [ejendomsfaktorDefinitioner]="vm.ejendomsfaktorDefinitioner"
              >
            </tilslutningsanmodning-step-ejendomsfaktorer>
          </div>
          <div class="step-actions">
            <button mat-flat-button (click)="stepper.previous()">Tilbage</button>
            <button type="submit" mat-flat-button color="primary" (click)="stepper.next()">Næste</button>
          </div>
        </div>
      </mat-step>
    }
    <mat-step [completed]="stepOplysninger.valid">
      <ng-template matStepLabel>Dine oplysninger</ng-template>
      <div>
        <div class="step-content">
          <dff-side-tekst
            [titel]="vm.konfiguration.steps.personligeOplysninger.overskrift"
            [tekst]="vm.konfiguration.steps.personligeOplysninger.tekst"
          ></dff-side-tekst>
          <tilslutningsanmodning-step-oplysninger
            #stepOplysninger
            [formGroup]="vm.formGroup.group('oplysninger')"
            [konfiguration]="vm.konfiguration"
            [adresse2]="vm.forsyningPaaAdresseResult.tekst"
          ></tilslutningsanmodning-step-oplysninger>
        </div>
        <div class="step-actions">
          <button mat-flat-button (click)="stepper.previous()">Tilbage</button>
          <button mat-flat-button color="primary" (click)="submit(vm, stepOplysninger, stepper)">
            {{ vm.konfiguration.steps.personligeOplysninger.sendKnapTekst }}
          </button>
        </div>
      </div>
      @if (submitter) {
        <spinner></spinner>
      }
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Kvittering</ng-template>
      <div>
        <div class="step-content">
          <dff-side-tekst
            [titel]="vm.konfiguration.steps.kvittering.overskrift"
            [tekst]="vm.konfiguration.steps.kvittering.tekst"
          ></dff-side-tekst>
        </div>
        <div class="step-actions">
          @if (false) {
            <button mat-flat-button (click)="stepper.previous()">Tilbage</button>
          }
        </div>
      </div>
    </mat-step>
  </mat-vertical-stepper>
} @else {
  <spinner></spinner>
}

