@if (vm$ | async; as vm) {
  @if (!vm.consentAccepted) {
    <dff-cookie-consent-overlay>
      <img [src]="imageUrl" class="img-responsive" width="100%" />
    </dff-cookie-consent-overlay>
  }
  @if (vm.consentAccepted) {
    <a [href]="videoUrl" data-fancybox="video" class="video">
      <img [src]="imageUrl" class="img-responsive" width="100%" />
      <div class="video__positioner">
        <span class="video__icon fa fa-video-camera {{ buttonClass }}"></span>
      </div>
    </a>
  }
}
