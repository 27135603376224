@if (step === 1) {
  <div class="step1" #step1>
    <div class="content">
      <img src="/assets/dffedb/images/e-boks_logo_1000x197.png" alt="E-boks logo" />
      <p>Vidste du, at du kan modtage post fra {{ firmaNavn$ | async }} direkte i din e-Boks?</p>
      <p>Tilmeld dig med det samme ved at klikke nedenfor.</p>
      <div class="button-panel">
        <button mat-flat-button color="primary" (click)="onNext()" id="button-ja-tak">Ja tak, jeg vil gerne tilmeldes e-Boks</button>
        <button mat-stroked-button (click)="onLuk()" id="button-spoerg-mig-senere">Ikke nu, men spørg mig igen senere</button>
        <button mat-stroked-button (click)="onLukOgSpoergMigIkkeIgen()">Nej tak, jeg ønsker ikke at modtage min post i e-Boks</button>
      </div>
    </div>
  </div>
}
@if (step === 2) {
  <div class="step2" [style.minHeight.px]="minHeight">
    <div class="top-bar">
      <button mat-icon-button (click)="onLuk()" aria-label="Luk E-boks popup">
        <mat-icon svgIcon="e-boks-dialog-close-icon"></mat-icon>
      </button>
    </div>
    <div class="content">
      <e-boks-tilmelding></e-boks-tilmelding>
    </div>
  </div>
}
