import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { MatListModule } from "@angular/material/list";

import { DffSidenavMenuComponent } from "./dff-sidenav-menu/dff-sidenav-menu.component";
import { MatDrawer, MatDrawerContainer, MatDrawerContent } from "@angular/material/sidenav";
import { SvgIconRegistry } from "@dffedb/util";
import { mdiArrowLeft, mdiArrowRight, mdiPause } from "@mdi/js";

@NgModule({
    imports: [CommonModule, MatIconModule, MatExpansionModule, RouterModule, MatListModule, MatDrawer, MatDrawerContainer, MatDrawerContent],
    declarations: [DffSidenavMenuComponent],
    exports: [DffSidenavMenuComponent]
})
export class MenuModule {
    constructor(svgIconRegistry: SvgIconRegistry) {
        svgIconRegistry.registerIcon(mdiArrowLeft, "arrow-left");
        svgIconRegistry.registerIcon(mdiArrowRight, "arrow-right");
        svgIconRegistry.registerIcon(mdiPause, "icon-pause");
    }
}
