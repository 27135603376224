<div class="popup">
  <a href="#" class="close-button" (click)="onHidePopup($event)" title="Luk">
    <mat-icon svgIcon="dff-info-popup-close"></mat-icon>
  </a>
  <div class="scroll-container">
    <div class="padding-container">
      @if (imageSrc) {
        <img [src]="imageSrc" alt="Billede" />
      }
      @if (header) {
        <h3>{{ header }}</h3>
      }
      @if (content) {
        <div [innerHtml]="content"></div>
      }
      @if (buttonHref && buttonText) {
        <a
          [href]="buttonHref"
          [target]="buttonTarget ?? '_self'"
          class="button"
          mat-flat-button
          color="primary"
          >{{ buttonText }}</a
          >
        }
      </div>
    </div>
  </div>

  <a class="toggle-corner" href="#" (click)="onShowPopup($event)" role="button" aria-label="Luk popup">
    <mat-icon svgIcon="dff-info-popup-open"></mat-icon>
  </a>
