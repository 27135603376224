<!-- 
    TODO 14420: Commented out for now, as it is now baked into angular slider.
    <div class="slider-value-label">
    <div class="inner" [style.transform]="getSliderValueLabelTransform()">
         {{ formatValue(matSlider?.step)}}
    </div>
</div> -->
<ng-content></ng-content>
<span class="slider-tick-label slider-tick-label-min">{{ formatValue(matSlider?.min) + suffix }}</span>
<span class="slider-tick-label slider-tick-label-max">{{ formatValue(matSlider?.max) + suffix }}</span>
