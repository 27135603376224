@if (href) {
    <a [href]="href" [id]="titel.replace(' ', '-') + 'link'" (click)="onClick($event, href)" [class]="class">
        <span>
            @if (svgIcon) {
                <mat-icon [svgIcon]="svgIcon"></mat-icon>
            }
            <span>{{ titel }}</span>
        </span>
    </a>
} @else {
    <button
        #dropdown
        [id]="titel.replace(' ', '-') + '-button'"
        (click)="onClick($event, href)"
        [class]="class + ' dff-dropdown-button'"
        role="button"
        type="button"
        aria-haspopup="true"
        aria-expanded="false"
    >
        <span>
            @if (svgIcon) {
                <mat-icon [svgIcon]="svgIcon"></mat-icon>
            }
            <span>{{ titel }}</span>
        </span>
    </button>
}
<div class="dropdown-content" [id]="titel.replace(' ', '-') + '-dropdown-content'" [attr.aria-labelledby]="titel.replace(' ', '-') + '-button'" role="menu">
    <ng-content></ng-content>
</div>
