@if (vm$ | async; as vm) {
  @if (vm.enabled) {
    <a href="#" (click)="onClick($event)">
      <div class="triangle"></div>
      <mat-icon svgIcon="dffedb-cookie-consent.cookie" aria-hidden="false" aria-label="Cookie Consent">home</mat-icon>
    </a>
    @if (vm.reloading) {
      <div class="overlay">
        <div>
          <mat-spinner class="mat-spinner"></mat-spinner>
          <h2>Genindlæser siden...</h2>
        </div>
      </div>
    }
  }
}
