<div class="table" role="table">
  @for (cookie of cookies; track cookie) {
    <div class="rowgroup" role="rowgroup">
      <div role="row">
        <div class="title" role="cell">Databehandler</div>
        <div class="content" role="cell">{{ cookie.processor }}</div>
      </div>
      <div role="row">
        <div class="title" role="cell">Navn</div>
        <div class="content" role="cell">{{ cookie.name }}</div>
      </div>
      @if (cookie.domain) {
        <div role="row">
          <div class="title" role="cell">Domæne</div>
          <div class="content" role="cell">{{ cookie.domain }}</div>
        </div>
      }
      <div role="row">
        <div class="title" role="cell">Udløb</div>
        <div class="content" role="cell">{{ cookie.expiry }}</div>
      </div>
      <div role="row">
        <div class="title" role="cell">Type</div>
        <div class="content" role="cell">{{ cookie.type }}</div>
      </div>
    </div>
  }
</div>
