@if (formControl && !hidden) {
  <div>
    @switch (true) {
      <!-- Tal -->
      @case (controlType === formControlType.NumberInput) {
        <mat-form-field>
          <mat-label>{{ placeholder }}</mat-label>
          <input type="number" matInput [formControl]="formControl" [required]="formControl.required" />
          <div matSuffix><ng-container *ngTemplateOutlet="matSuffix"></ng-container></div>
          @if (hint) {
            <mat-hint [innerHTML]="hint"></mat-hint>
          }
          @if (formControl.errors) {
            <mat-error>{{ getErrorText(formControl) }}</mat-error>
          }
        </mat-form-field>
      }
      <!-- Dropdown -->
      @case (controlType === formControlType.DropdownList) {
        <mat-form-field>
          <mat-label>{{ placeholder }}</mat-label>
          <mat-select [formControl]="formControl" [required]="formControl.required">
            @for (option of options$ | async; track option) {
              <mat-option [value]="option.key">{{ option.value }}</mat-option>
            }
          </mat-select>
          @if (hint) {
            <mat-hint [innerHTML]="hint"></mat-hint>
          }
          @if (formControl.errors) {
            <mat-error>{{ getErrorText(formControl) }}</mat-error>
          }
        </mat-form-field>
      }
      <!-- Autocomplete | AutocompleteNumeric -->
      @case (controlType === formControlType.Autocomplete || controlType === formControlType.AutocompleteNumeric) {
        <mat-form-field>
          <mat-label>{{ placeholder }}</mat-label>
          <input
            #SearchField
            matInput
            [type]="controlType === formControlType.AutocompleteNumeric ? 'number' : 'text'"
            [formControl]="formControl"
            autocorrect="off"
            [matAutocomplete]="auto"
            [required]="formControl.required"
            />
            <div matSuffix><ng-container *ngTemplateOutlet="matSuffix"></ng-container></div>
            @if (hint) {
              <mat-hint [innerHTML]="hint"></mat-hint>
            }
            @if (formControl.errors) {
              <mat-error>{{ getErrorText(formControl) }}</mat-error>
            }
            <mat-autocomplete #auto="matAutocomplete">
              @for (option of options$ | async; track option) {
                <mat-option [value]="option.key">
                  {{ option.value }}
                </mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        }
        <!-- Checkbox -->
        @case (controlType === formControlType.Checkbox) {
          <div>
            <mat-checkbox [formControl]="formControl" [required]="formControl.required">{{ placeholder }}</mat-checkbox>
            @if (formControl.errors) {
              <mat-error>{{ getErrorText(formControl) }}</mat-error>
            }
          </div>
        }
        <!-- Dato -->
        @case (controlType === formControlType.DateInput) {
          <mat-form-field>
            <mat-label>{{ placeholder }}</mat-label>
            <input
              matInput
              #dateinput
              [matDatepicker]="datepicker"
              [formControl]="formControl"
              [required]="formControl.required"
              (focus)="datepicker.open()"
              />
              <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker (closed)="dateinput.blur()"></mat-datepicker>
              @if (hint) {
                <mat-hint [innerHTML]="hint"></mat-hint>
              }
              @if (formControl.errors) {
                <mat-error>{{ getErrorText(formControl) }}</mat-error>
              }
            </mat-form-field>
          }
          <!-- Tekst (default/fallback) -->
          @default {
            <mat-form-field>
              <mat-label>{{ placeholder }}</mat-label>
              <input type="text" matInput [formControl]="formControl" [required]="formControl.required" />
              <div matSuffix><ng-container *ngTemplateOutlet="matSuffix"></ng-container></div>
              @if (hint) {
                <mat-hint [innerHTML]="hint"></mat-hint>
              }
              @if (formControl.errors) {
                <mat-error>{{ getErrorText(formControl) }}</mat-error>
              }
            </mat-form-field>
          }
        }
      </div>
    } @else {
      @if (!hidden) {
        <div class="error">Fejl: DffFormControlComponent.formControl forventer en FormControl-instans.</div>
      }
    }
    <ng-template #matSuffix>
      <span matSuffix>{{ suffix }}</span>
      <ng-content select="[matSuffix]"></ng-content>
    </ng-template>
