<table>
  @for (group of kvittering.groups; track group; let i = $index) {
    @if (i !== 0) {
      <tr class="spacer">
        <td colspan="2"><hr /></td>
      </tr>
    }
    <tr class="header">
      <td colspan="2">{{ group.title }}</td>
    </tr>
    @for (row of group.rows; track row) {
      <tr class="data">
        <td>{{ row.key }}:</td>
        <td [innerHTML]="row.value"></td>
      </tr>
    }
  }
</table>
