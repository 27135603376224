<mat-card>
  <mat-card-header>
    @if (titel) {
      <h2 class="mat-h1 mat-card-title" mat-card-title>{{ titel }}</h2>
    }
  </mat-card-header>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
</mat-card>
