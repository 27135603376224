<div id="top" #top></div>
@if (!(initialiserer$ | async)) {
  <mat-vertical-stepper [linear]="true" #stepper class="button-toggle" color="primary">
    <ng-template matStepperIcon="edit">
      <mat-icon svgIcon="e-boks-stepper-icon"></mat-icon>
    </ng-template>
    @if (afkraevAdressekode && (validererAdresseKode$ | async); as adresseKodeStatus) {
      <mat-step
        #stepAdresseKode
        [completed]="adresseKodeStatus.processed && !disabled"
        >
        <ng-template matStepLabel>Indtast din to-delte kode</ng-template>
        <form [formGroup]="formGroup" class="side-by-side" autocomplete="off" [class.errored]="adresseKodeStatus.errored">
          <step-adresse-kode
            #stepAdresseKode
            class="step-content"
            [disabled]="disabled"
            [adresseKodeStatus]="adresseKodeStatus"
            (adresseKodeChange)="onAdresseKodeChange($event)"
          ></step-adresse-kode>
          <div class="step-actions">
            <button
              #AdresseKodeNextButton
              mat-flat-button
              color="primary"
              [disabled]="!adresseKodeStatus.processed || disabled"
              (click)="stepper.next()"
              id="button-eboks-tilmelding-naeste1"
              >
              Næste
            </button>
          </div>
        </form>
      </mat-step>
    }
    @if ((tilmeldinger$ | async).length) {
      <mat-step [completed]="!disabled">
        <ng-template matStepLabel>Eksisterende tilmeldinger</ng-template>
        <div class="step-eksisterende-tilmeldinger">
          <step-eksisterende-tilmeldinger
            class="step-content"
            [disabled]="disabled"
            [tilmeldinger]="tilmeldinger$ | async"
            [afmeldStatus]="afmelder$ | async"
            (afmeld)="onAfmeld($event)"
          ></step-eksisterende-tilmeldinger>
          <div class="step-actions">
            <button
              mat-flat-button
              color="primary"
              [disabled]="disabled"
              (click)="stepper.next()"
              id="button-eboks-tilmelding-flere"
              [attr.aria-label]="'Gå til næste trin -' + stepIdentitet.title"
              role="button"
              type="button"
              >
              Tilmeld flere
            </button>
          </div>
        </div>
      </mat-step>
    }
    <mat-step [completed]="identitetFormGroup.valid && !disabled">
      <ng-template matStepLabel>{{ stepIdentitet.title }}</ng-template>
      <form [formGroup]="identitetFormGroup" class="step-identitet side-by-side" autocomplete="off">
        <step-identitet
          #stepIdentitet
          class="step-content"
          [formGroup]="identitetFormGroup"
          [revealOnFocus]="identitetButtonNext"
        ></step-identitet>
        <div class="step-actions">
          <button
            #identitetButtonNext
            mat-flat-button
            color="primary"
            [disabled]="identitetFormGroup.invalid || disabled"
            (click)="stepper.next()"
            aria-label="Gå til næste trin - Afgiv dit samtykke"
            type="button"
            role="button"
            id="button-eboks-tilmelding-naeste2"
            >
            Næste
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step [completed]="(tilmelder$ | async).processed && !disabled">
      <ng-template matStepLabel>Afgiv dit samtykke</ng-template>
      @if (tilmelder$ | async; as tilmelder) {
        <form [formGroup]="samtykkeFormGroup" class="step-samtykke" id="sammentykke-form">
          <step-samtykke
            #stepSamtykke
            class="step-content"
            [formGroup]="samtykkeFormGroup"
            [samtykkeTekst]="samtykkeTekst$ | async"
          ></step-samtykke>
          @if (tilmelder.error) {
            <div class="error">{{ tilmelder.error }}</div>
          }
          <div class="step-actions">
            <dff-spinner-button
              color="primary"
              [disabled]="formGroup.invalid || disabled"
              [spinning]="tilmelder.processing"
              (click)="onTilmeld()"
              id="button-eboks-godkend"
              role="button"
              type="submit"
              aria-label="Godkend tilmelding til e-Boks"
              >Godkend</dff-spinner-button
              >
            </div>
          </form>
        }
      </mat-step>
    </mat-vertical-stepper>
  } @else {
    <mat-spinner class="mat-spinner"></mat-spinner>
  }
  <div #bottom></div>

