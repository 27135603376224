import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Injectable({ providedIn: "root" })
export class SvgIconRegistry {
    constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {}

    public registerIcon3(svgData: string, width: number, height: number, ...iconName: string[]): void {
        iconName.forEach((name) => {
            this.matIconRegistry.addSvgIconLiteral(name, this.createSvgTag2(svgData, width, height));
        });
    }

    public registerIcon2(svgData: string, width: number, height: number, ...iconName: string[]): void {
        iconName.forEach((name) => {
            this.matIconRegistry.addSvgIconLiteral(name, this.createSvgTag(svgData, width, height));
        });
    }

    public registerIcon(svgData: string, ...iconName: string[]): void {
        iconName.forEach((name) => {
            this.matIconRegistry.addSvgIconLiteral(name, this.createSvgTag(svgData, 24, 24));
        });
    }

    private createSvgTag2(icon: string, width: number, height: number): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(
            // eslint-disable-next-line max-len
            `<svg viewBox="0 0 ${width} ${height}" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">${icon}</svg>`
        );
    }

    private createSvgTag(icon: string, width: number, height: number): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(
            // eslint-disable-next-line max-len
            `<svg viewBox="0 0 ${width} ${height}" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><path d="${icon}"></path></svg>`
        );
    }
}
