<span (click)="killHostClickListener($event)">
  <button
    mat-flat-button
    [color]="color"
    (click)="onClick($event)"
    [disabled]="disabled || spinning"
    [class.spinning]="spinning"
    role="button"
    [attr.type]="buttonType"
    >
    @if (spinning) {
      <mat-spinner class="mat-spinner" color="basic" diameter="25"></mat-spinner>
    }
    <ng-content></ng-content>
  </button>
</span>
