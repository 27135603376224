import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { PrisberegnerFormModel } from "../model/prisberegner-form.model";
import {
    PrisberegnerFjernvarmeBbrPrisKonfiguration,
    PrisberegnerFjernvarmeKonfiguration,
    PrisberegnerKonfiguration,
    PrisberegnerKonfigurationVirkningsgrad,
    PrisberegnerNaturgasKonfiguration,
    PrisberegnerOlieKonfiguration,
    PrisberegnerTraepillerKonfiguration,
    PrisberegnerVarmepumpeKonfiguration
} from "../model/prisberegner-konfiguration.model";
import {
    PrisberegnerResultat,
    PrisberegnerResultatFjernvarme,
    PrisberegnerResultatFjernvarmeBbrBidrag,
    PrisberegnerResultatNaturgas,
    PrisberegnerResultatOlie,
    PrisberegnerResultatTraepiller,
    PrisberegnerResultatVarmepumpe
} from "../model/prisberegner-resultat.model";
import { prisberegnerVarmekilder } from "../model/prisberegner-varmekilder.model";
import { PrisberegnerRepository } from "./prisberegner.repository";

@Injectable({ providedIn: "root" })
export class PrisberegnerService {
    constructor(private readonly repository: PrisberegnerRepository) {}

    public selectKonfiguration(nodeId: number): Observable<PrisberegnerKonfiguration> {
        return this.repository.selectKonfiguration(nodeId);
    }

    public beregnForbrugPrAar(model: PrisberegnerFormModel, konfiguration: PrisberegnerKonfiguration): PrisberegnerResultat {
        const mwhEl = model.varmekilde === prisberegnerVarmekilder.el.id ? this.beregnForbrugEl(model.forbrugEl) : 0;
        const mwhNaturgas =
            model.varmekilde === prisberegnerVarmekilder.naturgas.id
                ? this.beregnForbrugNaturgas(model.naturgasKeddelAlder, model.forbrugNaturgas, konfiguration.naturgas)
                : 0;
        const mwhOlie =
            model.varmekilde === prisberegnerVarmekilder.olie.id
                ? this.beregnForbrugOlie(model.olieFyrAlder, model.forbrugOlie, konfiguration.olie)
                : 0;
        const mwhTraepiller =
            model.varmekilde === prisberegnerVarmekilder.traepiller.id
                ? this.beregnForbrugTraepiller(model.pillefyrAlder, model.forbrugTraepiller, konfiguration.traepiller)
                : 0;
        const mwhVarmepumpe =
            model.varmekilde === prisberegnerVarmekilder.varmepumpe.id
                ? this.beregnForbrugVarmepumpe(model.forbrugVarmepumpe, konfiguration.varmepumpe)
                : 0;

        const mwhIalt = mwhEl + mwhNaturgas + mwhOlie + mwhTraepiller + mwhVarmepumpe;
        const fjernvarme = this.beregnPriserFjernvarme(
            konfiguration.fjernvarme,
            model.boligareal,
            mwhIalt,
            model.oenskerVarmemesterOrdning,
            model.oenskerFordelingAfKonverteringsbidrag ? model.konverteringbidragAntalAar : -1
        );
        const naturgas = this.beregnPriserNaturgas(konfiguration.naturgas, model.forbrugNaturgas);
        const olie = this.beregnPriserOlie(konfiguration.olie, model.forbrugOlie);
        const traepiller = this.beregnPriserTraepiller(konfiguration.traepiller, model.forbrugTraepiller);
        const varmepumpe = this.beregnPriserVarmepumpe(konfiguration.varmepumpe, model.forbrugVarmepumpe);
        const result: PrisberegnerResultat = {
            mwh: mwhIalt,
            fjernvarme,
            naturgas,
            olie,
            traepiller,
            varmepumpe
        };
        return result;
    }

    private beregnPriserFjernvarme(
        konfiguration: PrisberegnerFjernvarmeKonfiguration,
        boligareal: number,
        mwhIalt: number,
        oenskerVarmemesterOrdning: boolean,
        konverteringsbidragAntalAarParm: number
    ): PrisberegnerResultatFjernvarme {
        const abonnement = Math.round(konfiguration.prisAbonnement);
        const bbrBidrag = Math.round(this.beregnPrisBBR(boligareal, konfiguration));
        const bbrBidragTrappemodel = this.beregBBRTrappemodel(boligareal, konfiguration);
        //const bbrBidragTrappemodel = Math.round(this.beregnPrisBBRTrappemodel(boligareal, konfiguration));
        const forbrug = Math.round(mwhIalt * konfiguration.prisPrMwh);
        const groenOmstillingsbidrag = Math.round(konfiguration.groenOmstillingsbidragPrM2 * boligareal);
        const tilslutningsbidrag = Math.round(konfiguration.tilslutningsbidrag);
        const serviceOgVedligehold = Math.round(oenskerVarmemesterOrdning ? 0 : konfiguration.serviceOgVedligeholdPrAar);
        const investeringsomkostningerYdelse = Math.round(
            oenskerVarmemesterOrdning
                ? 0
                : this.beregnYdelsePrAar(
                      konfiguration.investeringsomkostninger,
                      konfiguration.nyUnitAfskrivningsperiodeAar,
                      konfiguration.nyUnitRentesats
                  )
        );
        const unitGenanskaffelseYdelse = Math.round(
            oenskerVarmemesterOrdning
                ? 0
                : this.beregnYdelsePrAar(
                      konfiguration.nyUnitPris,
                      konfiguration.nyUnitAfskrivningsperiodeAar,
                      konfiguration.nyUnitRentesats
                  )
        );
        const varmemesterOrdning = Math.round(oenskerVarmemesterOrdning ? konfiguration.varmemesterordningPrAar : 0);
        /*let konverteringsbidragPraar = Math.round(
            konverteringsbidragAntalAarParm > 0
                ? konfiguration.konverteringsbidrag / konverteringsbidragAntalAarParm
                : konfiguration.konverteringsbidrag
        );*/
        //konverteringsbidragPraar = konverteringsbidragAntalAarParm === -1 ? 0 : konverteringsbidragPraar;
        //const konverteringsbidrag = konfiguration.konverteringsbidrag;
        const bbrBidragTrappemodelIalt = bbrBidragTrappemodel.ialt ? bbrBidragTrappemodel.ialt : null;
        const konverteringsbidragIalt =
            konverteringsbidragAntalAarParm > 0 ? bbrBidragTrappemodel.konverteringsbidragIalt / konverteringsbidragAntalAarParm : 0;
        const konverteringsbidragAntalAarValgt = konverteringsbidragAntalAarParm; //vi skal bruge den valgte, ikke def
        const ialt =
            forbrug +
            abonnement +
            bbrBidrag +
            bbrBidragTrappemodelIalt +
            groenOmstillingsbidrag +
            serviceOgVedligehold +
            investeringsomkostningerYdelse +
            unitGenanskaffelseYdelse +
            varmemesterOrdning +
            konverteringsbidragIalt;

        return {
            forbrug,
            abonnement,
            bbrBidrag,
            bbrBidragTrappemodel,
            groenOmstillingsbidrag,
            tilslutningsbidrag,
            serviceOgVedligehold,
            investeringsomkostningerYdelse,
            unitGenanskaffelseYdelse,
            varmemesterOrdning,
            //konverteringsbidrag,
            //konverteringsbidragPraar,
            konverteringsbidragAntalAarValgt,
            ialt
        };
    }

    private beregnPriserNaturgas(konfiguration: PrisberegnerNaturgasKonfiguration, forbrugM3: number): PrisberegnerResultatNaturgas {
        const abonnement = Math.round(konfiguration.abonnementPrAar);
        const systemtarif = Math.round(konfiguration.systemtarif);
        const forbrug = Math.round(forbrugM3 * konfiguration.prisPrM3);
        const serviceOgVedligehold = Math.round(konfiguration.serviceOgVedligeholdPrAar);
        const elForbrug = Math.round(konfiguration.elPrAar);
        const unitGenanskaffelseYdelse = Math.round(
            this.beregnYdelsePrAar(konfiguration.nytFyrPris, konfiguration.nytFyrAfskrivningsperiodeAar, konfiguration.nytFyrRentesats)
        );
        const ialt = abonnement + systemtarif + forbrug + serviceOgVedligehold + elForbrug + unitGenanskaffelseYdelse;
        return {
            abonnement,
            systemtarif,
            forbrug,
            serviceOgVedligehold,
            elForbrug,
            unitGenanskaffelseYdelse,
            ialt
        };
    }

    private beregnPriserOlie(konfiguration: PrisberegnerOlieKonfiguration, forbrugLiter: number): PrisberegnerResultatOlie {
        const forbrug = Math.round(forbrugLiter * konfiguration.prisPrLiter);
        const skorstensfejer = Math.round(konfiguration.skorstensfejerPrAar);
        const serviceOgVedligehold = Math.round(konfiguration.serviceOgVedligeholdPrAar);
        const elForbrug = Math.round(konfiguration.elPrAar);
        const unitGenanskaffelseYdelse = Math.round(
            this.beregnYdelsePrAar(konfiguration.nytFyrPris, konfiguration.nytFyrAfskrivningsperiodeAar, konfiguration.nytFyrRentesats)
        );
        const ialt = forbrug + skorstensfejer + serviceOgVedligehold + elForbrug + unitGenanskaffelseYdelse;
        return {
            forbrug,
            skorstensfejer,
            serviceOgVedligehold,
            elForbrug,
            unitGenanskaffelseYdelse,
            ialt
        };
    }

    private beregnPriserTraepiller(konfiguration: PrisberegnerTraepillerKonfiguration, forbrugKg: number): PrisberegnerResultatTraepiller {
        const forbrug = Math.round(forbrugKg * konfiguration.prisPrKg);
        const skorstensfejer = Math.round(konfiguration.skorstensfejerPrAar);
        const serviceOgVedligehold = Math.round(konfiguration.serviceOgVedligeholdPrAar);
        const elForbrug = Math.round(konfiguration.elPrAar);
        const unitGenanskaffelseYdelse = Math.round(
            this.beregnYdelsePrAar(konfiguration.nytFyrPris, konfiguration.nytFyrAfskrivningsperiodeAar, konfiguration.nytFyrRentesats)
        );
        const ialt = forbrug + skorstensfejer + serviceOgVedligehold + elForbrug + unitGenanskaffelseYdelse;
        return {
            forbrug,
            skorstensfejer,
            serviceOgVedligehold,
            elForbrug,
            unitGenanskaffelseYdelse,
            ialt
        };
    }

    private beregnPriserVarmepumpe(konfiguration: PrisberegnerVarmepumpeKonfiguration, forbrugKwh: number): PrisberegnerResultatVarmepumpe {
        const elUdgift = Math.round(forbrugKwh * konfiguration.elprisPrKwh);
        const serviceOgVedligehold = Math.round(konfiguration.serviceOgVedligeholdPrAar);
        const unitGenanskaffelseYdelse = Math.round(
            this.beregnYdelsePrAar(konfiguration.nyPumpePris, konfiguration.nyPumpeAfskrivningsperiodeAar, konfiguration.nyPumpeRentesats)
        );
        const ialt = elUdgift + serviceOgVedligehold + unitGenanskaffelseYdelse;
        return {
            elUdgift,
            serviceOgVedligehold,
            unitGenanskaffelseYdelse,
            ialt
        };
    }

    private beregnYdelsePrAar(pris: number, antalAar: number, rente: number): number {
        // https://superuser.com/questions/871404#answer-871411
        const result = (pris * (rente / 100)) / (1 - (1 + rente / 100) ** (-1 * antalAar));
        return isNaN(result) ? 0 : result;
    }

    private beregnPrisBBR(boligareal: number, konfiguration: PrisberegnerFjernvarmeKonfiguration): number {
        return (boligareal || 0) * konfiguration.bbrPrisPrM2;
    }

    private beregBBRTrappemodel(
        boligareal: number,
        konfiguration: PrisberegnerFjernvarmeKonfiguration
    ): PrisberegnerResultatFjernvarmeBbrBidrag {
        const konfigurationer = this.mapBbrKonfiguration(boligareal, konfiguration.bbrPriserPrM2);
        return {
            ialt: this.beregnPrisBBRTrappemodel(boligareal, konfiguration),
            konverteringsbidragIalt: this.beregnPrisKonverteringsbidragTappemodel(boligareal, konfiguration),
            items: konfigurationer
        };
    }

    private beregnPrisBBRTrappemodel(boligareal: number, konfiguration: PrisberegnerFjernvarmeKonfiguration): number {
        return konfiguration.bbrPriserPrM2.reduce((prev, curr) => {
            const result = this.beregnPrisBBRTrappemodelItem(boligareal, curr);
            return prev + result;
        }, 0);
    }

    private beregnPrisKonverteringsbidragTappemodel(boligareal: number, konfiguration: PrisberegnerFjernvarmeKonfiguration): number {
        return konfiguration.bbrPriserPrM2.reduce((prev, curr) => {
            const result = this.beregnPrisKonverteringsbidragTrappemodelItem(boligareal, curr);
            return prev + result;
        }, 0);
    }

    private beregnPrisKonverteringsbidragTrappemodelItem(
        boligareal: number,
        konfiguration: PrisberegnerFjernvarmeBbrPrisKonfiguration
    ): number {
        const fraM2 = konfiguration.fraM2 === 0 ? 1 : konfiguration.fraM2;
        const tilM2 = konfiguration.tilM2;
        const prisPrM2 = konfiguration.konverteringsbidragPrM2;

        // BBR mindre end konfigurationen: Ignorer konfigurationen
        if (boligareal < fraM2) {
            return 0;
        }
        // BBR større end konfigurationen: Brug hele konfigurationen
        if (boligareal > tilM2 && !!tilM2) {
            return (tilM2 - fraM2 + 1) * prisPrM2;
        }
        // BBR inden for konfigurationen: Brug noget af konfigurationen
        return (boligareal - fraM2 + 1) * prisPrM2;
    }

    private beregnPrisBBRTrappemodelItem(boligareal: number, konfiguration: PrisberegnerFjernvarmeBbrPrisKonfiguration): number {
        const fraM2 = konfiguration.fraM2 === 0 ? 1 : konfiguration.fraM2;
        const tilM2 = konfiguration.tilM2;
        const prisPrM2 = konfiguration.prisPrM2;

        // BBR mindre end konfigurationen: Ignorer konfigurationen
        if (boligareal < fraM2) {
            return 0;
        }
        // BBR større end konfigurationen: Brug hele konfigurationen
        if (boligareal > tilM2 && !!tilM2) {
            return (tilM2 - fraM2 + 1) * prisPrM2;
        }
        // BBR inden for konfigurationen: Brug noget af konfigurationen
        return (boligareal - fraM2 + 1) * prisPrM2;
    }

    private mapBbrKonfiguration(
        boligareal: number,
        konfigurationer: PrisberegnerFjernvarmeBbrPrisKonfiguration[]
    ): PrisberegnerFjernvarmeBbrPrisKonfiguration[] {
        // Fjerner konfigurationer som ikke er relevante + tilretter med faktisk boligareal
        return konfigurationer
            .filter((konfiguration) => konfiguration.fraM2 <= boligareal)
            .map((konfiguration) => ({
                tekst: konfiguration.tekst,
                fraM2: konfiguration.fraM2,
                tilM2: konfiguration.tilM2 <= boligareal ? konfiguration.tilM2 : boligareal,
                prisPrM2: konfiguration.prisPrM2,
                konverteringsbidragPrM2: konfiguration.konverteringsbidragPrM2
            }));
    }

    private beregnForbrugEl(forbrug: number): number {
        return (forbrug || 0) / 1000;
    }

    private beregnForbrugNaturgas(keddelAlder: number, forbrug: number, konfiguration: PrisberegnerNaturgasKonfiguration): number {
        const virkningsgrad = this.findVirkningsgrad(keddelAlder, konfiguration.virkningsgrad);
        return ((forbrug || 0) * konfiguration.braendvaerdiKwhPrM3 * virkningsgrad) / 1000;
    }

    private beregnForbrugOlie(keddelAlder: number, forbrug: number, konfiguration: PrisberegnerOlieKonfiguration): number {
        const virkningsgrad = this.findVirkningsgrad(keddelAlder, konfiguration.virkningsgrad);
        return ((forbrug || 0) * konfiguration.braendvaerdiKwhPrLiter * virkningsgrad) / 1000;
    }

    private beregnForbrugTraepiller(keddelAlder: number, forbrug: number, konfiguration: PrisberegnerTraepillerKonfiguration): number {
        const virkningsgrad = this.findVirkningsgrad(keddelAlder, konfiguration.virkningsgrad);
        return ((forbrug || 0) * konfiguration.braendvaerdiKwhPrKg * virkningsgrad) / 1000;

        // return ((forbrug || 0) * konfiguration.braendvaerdiKwhPrKg * konfiguration.virkningsgrad) / 1000;
    }

    private beregnForbrugVarmepumpe(forbrug: number, konfiguration: PrisberegnerVarmepumpeKonfiguration): number {
        return ((forbrug || 0) * konfiguration.virkningsgrad) / 1000;
    }

    private findVirkningsgrad(keddelAlder: number, konfiguration: PrisberegnerKonfigurationVirkningsgrad): number {
        if (!konfiguration.vaelgKedlensAlder) {
            return konfiguration.virkningsgrad;
        }
        switch (keddelAlder) {
            case 1:
                return konfiguration.virkningsgrad0til4Aar;
            case 2:
                return konfiguration.virkningsgrad5til8Aar;
            default:
                return konfiguration.virkningsgrad9tilNAar;
        }
    }
}
