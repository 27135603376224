@if (vm$ | async; as vm) {
    <dff-sidenav-menu #DffSidenavMenu [items]="vm.menuItems" (itemClick)="itemClick.emit($event)" id="eforsyning-sidenav-menu" role="list" id="sidenav-menu">
        @if (vm.visInstallationMenuItem) {
            @if (vm.vaelgInstallationHref) {
                <li class="aktuel-installation">
                    <a [href]="vm.vaelgInstallationHref" (click)="DffSidenavMenu.onItemClick($event, vm.vaelgInstallationHref)">
                        @if (vm.aktuelInstallation?.adresse) {
                            <div class="adresse">{{ vm.aktuelInstallation.adresse }}</div>
                        }
                        @if (vm.aktuelInstallation?.maalerNr) {
                            <small>Måler: {{ vm.aktuelInstallation.maalerNr }}</small>
                        }
                        @if (!vm.aktuelInstallation?.maalerNr) {
                            <small>Status: Passiv</small>
                        }
                        @if (vm.vaelgInstallationHref) {
                            <small class="skift">SKIFT...</small>
                        }
                    </a>
                </li>
            } @else {
                <li class="aktuel-installation">
                    <div>
                        @if (vm.aktuelInstallation?.adresse) {
                            <span class="adresse">{{ vm.aktuelInstallation.adresse }}</span>
                        }
                        @if (vm.aktuelInstallation?.maalerNr) {
                            <small>Måler: {{ vm.aktuelInstallation.maalerNr }}</small>
                        }
                        @if (!vm.aktuelInstallation?.maalerNr) {
                            <small>Status: Passiv</small>
                        }
                        @if (vm.vaelgInstallationHref) {
                            <small class="skift">SKIFT...</small>
                        }
                    </div>
                </li>
            }
        }
    </dff-sidenav-menu>
} @else {
    <div class="spinner-container">
        <mat-spinner class="mat-spinner"></mat-spinner>
    </div>
}
<ng-template #spinner>
    <div class="spinner-container">
        <mat-spinner class="mat-spinner"></mat-spinner>
    </div>
</ng-template>
