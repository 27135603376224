@if (data.titel) {
  <h2 mat-dialog-title [attr.id]="dialogTitle" class="mat-dialog-title">{{ data.titel }}</h2>
}
<mat-dialog-content><div [outerHTML]="data.tekst" [attr.id]="dialogDesc"></div></mat-dialog-content>
<mat-dialog-actions>
  @for (knap of data.knapper; track knap; let i = $index) {
    <button
      mat-button
      [mat-dialog-close]="knap"
      id="{{ 'dff-dialog-button_' + i }}"
      role="button"
      aria-label=""
      >
      {{ knap }}
    </button>
  }
</mat-dialog-actions>
